<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Feed Type</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">

              <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()">
                Add
              </button>

              <table class="table table-striped table-bordered" style="margin-top: 10px">
                <thead>
                <tr>
                  <th>Date Created</th>
                  <th>Name</th>
                  <th>Crude Protein Percentage</th>
                  <th>Energy Per Kilogram</th>
                  <th>Lysine Percentage</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody *ngIf="feedTypes">
                <tr *ngFor="let feedType of feedTypes">
                  <td>{{feedType.dateTimeInserted | date:'dd MMM yyyy'}}</td>
                  <td>{{feedType.name}}</td>
                  <td>{{feedType.crudeProteinPercentage | percent}}</td>
                  <td>{{feedType.energyPerKilogram| number}}</td>
                  <td>{{feedType.lysinePercentage | percent}}</td>
                  <td>
                    <button type="button" class="btn btn-link" title="End User Session" (click)="edit(feedType)">
                      <span class="fa fa-edit" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
