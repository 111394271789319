import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {StandardImport} from '../standard-import';
import bsCustomFileInput from 'bs-custom-file-input';
import {Route, Router} from '@angular/router';

@Component({
  selector: 'app-standard-list',
  templateUrl: './standard-list.component.html',
  styleUrls: ['./standard-list.component.css']
})
export class StandardListComponent implements OnInit {

  private importFile!: File;
  standardHeader: StandardImport = { name: ''} as StandardImport;
  standards = [] as StandardImport[];

  constructor(private backendService: BackendService,
              private router: Router) {
  }

  ngOnInit(): void {
    bsCustomFileInput.init()
    this.loadStandards();
  }

  private loadStandards() {
    this.backendService.get<StandardImport[]>('standard').then((res: StandardImport[]) => {
      this.standards = res;
    });
  }

  uploadFiles(fileList: FileList) {
    console.log('file', fileList);
    this.importFile = fileList[0];
  }

  import() {
    console.log(this.standardHeader.name);
    if (this.importFile) {
      const fileReader: FileReader = new FileReader();
      const self = this;
      fileReader.onloadend = (x: any) => {
        self.standardHeader.importedFile = fileReader.result;
        self.backendService.postSilently('standard/import', self.standardHeader)
          .then((res: any) => {
            self.loadStandards();
          });
      }
      fileReader.readAsText(this.importFile);
    }
  }

  details(standardHeader: StandardImport) {
    this.router.navigate(['standard', 'view', standardHeader.id]);
  }
}
