import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {House} from "../../farm/house/house";
import {AuthService} from "../../auth/auth.service";
import {DatePipe, formatDate} from "@angular/common";
import {ModalService} from "../../util/modal.service";
import {Temperature} from "../temperature";
import {TemperatureFormComponent} from "../temperature-form/temperature-form.component";

@Component({
  selector: 'app-temperature-list',
  templateUrl: './temperature-list.component.html',
  styleUrls: ['./temperature-list.component.css']
})
export class TemperatureListComponent implements OnInit {
  houses!: House[];
  fromDate = new Date();
  toDate = new Date();
  houseId = '';
  temperatures!: Temperature[];
  private farmId!: string;

  constructor(public authService: AuthService,
              private backendService: BackendService,
              private datePipe: DatePipe,
              private modalService: ModalService) {
  }

  ionViewWillEnter() {
    this.ngOnInit();
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.houses = await this.backendService.get('house/' + this.farmId);
    await this.search();
  }

  async search() {
    const fromDate = this.fromDate.getTime();
    const toDate = this.toDate.getTime();
    this.temperatures = await this.backendService.postSilently('temperature',
      {houseId: this.houseId, fromDate: fromDate, toDate: toDate});
  }

  status(status: string) {
    switch (status) {
      case 'Pending':
        return 'text-warning';
      case 'Confirmed':
        return 'text-success';
      case 'Exception':
        return 'text-danger';
    }
    return 'text-info';
  }

  async add() {
    const modalRef = this.modalService.openRef(TemperatureFormComponent, 'lg');
    const component = modalRef.componentInstance as TemperatureFormComponent;
    component.title = 'Add temperature ';
    component.temperature = {} as Temperature;
    await modalRef.result;
    this.search();
  }

  async edit(temperature: Temperature) {
    const modalRef = this.modalService.openRef(TemperatureFormComponent, 'lg');
    const component = modalRef.componentInstance as TemperatureFormComponent;
    component.title = 'Edit temperature ';
    component.temperature = temperature;
    await modalRef.result;
    this.search();
  }

  async delete(collection: Temperature) {
    this.modalService.confirm('Deleting temperature (' + collection.house + formatDate(this.fromDate, ' - dd MMMM yyyy', 'en')
      + ') will erase it from the system. Are you sure you want to delete this temperature?' )
      .then( response => {
          if (response){
            this.backendService.delete('temperature/' + collection.id).then( response => {
              this.modalService.info('temperature successfully deleted');
              this.search();
            });
          }
        }, errObj => {
          this.modalService.error('Error occured while deleting the collection.');
        }
      );
  }

  standardStatus(standard: number, actual: number) {
    if(standard !== actual) {
      return 'text-right text-danger font-weight-bold';
    } else {
      return 'text-right text-success font-weight-bold';
    }
  }
}
