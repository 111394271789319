<section class="container-fluid content-area">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Today Egg Collection
        </div>
        <div class="card-body">
          <div class="col-sm-12">

            <table class="table table-responsive-sm table-hover table-outline mb-0">
              <thead class="thead-light">
              <tr>
                <th class="text-center"><i class="fas fa-warehouse"></i></th>
                <th class="text-center">Flock</th>
                <th class="text-center">Total Collections</th>
                <th class="text-center">Collected/Target</th>
                <th class="text-center">Collected/Checked</th>
                <th class="text-center">Last Collection</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let todayCollection of todayCollections">
                <td class="text-center">{{todayCollection.house}} </td>
                <td class="text-center">
                  <a routerLink="/flock/view/{{todayCollection.flockId}}">{{todayCollection.flock}}</a> 
                </td>
                <td class="text-center">{{todayCollection.totalCollections}}</td>
                <td class="text-center">
                  <div class="clearfix">
                    <div class="float-left">
                      <strong>{{(todayCollection.totalCollected / todayCollection.collectionTarget) | percent}}</strong>
                    </div>
                    <div class="float-right">
                      <small class="text-muted">{{todayCollection.totalCollected}} / {{todayCollection.collectionTarget}}</small>
                    </div>
                  </div>
                    <ngb-progressbar [value]="(todayCollection.totalCollected / todayCollection.collectionTarget) * 100">
                    </ngb-progressbar>
                </td>
                <td class="text-center">
                  <div class="clearfix">
                    <div class="float-left">
                      <strong>{{(todayCollection.totalChecked / todayCollection.totalCollected) | percent}}</strong>
                    </div>
                    <div class="float-right">
                      <small class="text-muted">{{todayCollection.totalChecked}} / {{todayCollection.totalCollected}}</small>
                    </div>
                  </div>
                  <ngb-progressbar [value]="(todayCollection.totalChecked / todayCollection.totalCollected)  * 100">
                  </ngb-progressbar>
                </td>
                <td class="text-center">
                  {{todayCollection.lastCollectionTime | date: 'HH:mm'}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
