import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Directive({
  selector: '[appMax]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true },
  ],
})
export class MaxValidatorDirective implements Validator {
  @Input('appMax') max: number | null = null;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.max !== null ? Validators.max(this.max)(control) : null;
  }
}
