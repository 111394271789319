import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  message = '';

  constructor(public activeModal: NgbActiveModal) {}
}
