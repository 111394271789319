<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light" *ngIf="farms">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Farms</h1>
            </div>
          </div>
        </div>
        <div class="card-body">

          <button type="button" class="btn btn-primary btn-sm mb-" (click)="add()">
            Add
          </button>
          <table class="table table-striped table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Date Created</th>
              <th>Name</th>
              <th>Manager</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody *ngIf="farms">
            <tr *ngFor="let farm of farms">
              <td>{{farm.dateTimeInserted | date : 'dd MMM yyyy'}}</td>
              <td>{{farm.name}}</td>
              <td>{{farm.owner}}</td>
              <td>{{farm.status}}</td>
              <td>
                <button type="button" class="btn btn-link" title="Edit"
                        (click)="edit(farm)">
                  <span class="fas fa-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-link" title="Details"
                        (click)="details(farm)">
                  <span class="fas fa-eye" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>