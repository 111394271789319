<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">
  <form #bodyWeightForm="ngForm" (ngSubmit)="save()" *ngIf="bodyWeight">
    <div class="form-row row">
      <div class="form-group col">
        <label for="house">House</label>
        <app-drop-down id="house" name="house" required stretch="true" [disabled]="bodyWeight.id"
                       [(ngModel)]="bodyWeight.houseId">
          <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="gender">Gender</label>
        <app-drop-down id="gender" name="gender" required stretch="true" [disabled]="bodyWeight.id"
                       [(ngModel)]="bodyWeight.gender">
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="bodyWeightDate">Date</label>
        <div class="input-group">
          <input id="bodyWeightDate" name="bodyWeightDate" class="form-control" placeholder="dd-mm-yyyy"
                 (ngModelChange)="getActiveHouses()"
                 [(ngModel)]="bodyWeightDate" #bodyWeightDatePicker="ngbDatepicker" [readOnly]="bodyWeight.id"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt" [disabled]="bodyWeight.id"
                    (click)="bodyWeightDatePicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="transferIn">Body Weight</label>
        <app-text-input type="number" id="transferIn"
                        name="transferIn" [(ngModel)]="bodyWeight.actual" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!bodyWeightForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>