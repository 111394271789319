<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Egg Collections</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-inline">
            <div class="form-group mb-2">
              <label>Date</label>
              <div class="input-group mx-sm-3 mb-2">
                <input id="startDate" name="startDate" class="form-control" placeholder="yyyy-mm-dd"
                       [(ngModel)]="collectionDate" #birthdatePicker="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="birthdatePicker.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mx-sm-3 mb-2">
              <label>House</label>
              <select class="form-control mx-sm-3 mb-2" id="site" name="site"
                      [(ngModel)]="collectionHouseId">
                <option value="">All</option>
                <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
              </select>
            </div>
            <button type="submit" class="btn-sm btn-primary mx-sm-3  mb-2" (click)="getCollections()" ngbAutofocus>
              Search
            </button>

            <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()">
              Add
            </button>
          </div>
          <table class="table table-striped table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>House</th>
              <th>Time</th>
              <th>Completed By</th>
              <th>Checked By</th>
              <th>Nest Box</th>
              <th>Jumbo</th>
              <th>M/Shape</th>
              <th>Dirties</th>
              <th>Floor</th>
              <th>Soft Shell</th>
              <th>Small</th>
              <th>Cracks</th>
              <th>Broken</th>
              <th>Total Rejects</th>
              <th>Total Eggs</th>
              <th>Checked</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody *ngIf="collections">
            <tr *ngFor="let collection of collections">
              <td>{{collection.house}}</td>
              <td>{{collection.collectedDateTime | date : 'HH:mm'}}</td>
              <td>{{collection.completedBy}}</td>
              <td>{{collection.checkedBy}}</td>
              <td>{{collection.nestBox}}</td>
              <td>{{collection.jumbo}}</td>
              <td>{{collection.mShape}}</td>
              <td>{{collection.dirties}}</td>
              <td>{{collection.floor}}</td>
              <td>{{collection.softShell}}</td>
              <td>{{collection.small}}</td>
              <td>{{collection.cracks}}</td>
              <td>{{collection.broken}}</td>
              <td>{{collection.totalRejected}}</td>
              <td>{{collection.total}}</td>
              <td>{{collection.totalChecked}}</td>
              <td [class]="status(collection.status)"> {{collection.status}}</td>
              <td>
                <button type="button" class="btn btn-link" title="Edit" *ngIf="collection.status === 'Exception'
                || authService.hasAuthority('Data Capture')"
                        (click)="edit(collection)">
                  <span class="fas fa-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-link" title="Edit"
                        *ngIf="authService.hasAuthority('Data Capture')"
                        (click)="delete(collection)">
                  <span class="fas fa-minus-circle" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>