<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>{{standardHeader.name}} Standards</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th title="Weeks of Age end of the week">Weeks</th>
              <th title="Days of age end of the week">Days</th>
              <th title="Body weight - Target Male">Target Male Weight (g)</th>
              <th title="Body weight - Target Female Out-of-Season">Target Out-of-Season Female Weight (g)</th>
              <th title="Body weight - Target Female In-season">Target In-Season Female Weight (g)</th>
              <th title="Target Feed/Bird/Day Male">Target Male Daily Feed</th>
              <th title="Target Feed/Bird/Day Female Out-of-season">Target Out-of-Season Female Daily Feed</th>
              <th title="Target Feed/Bird/Day Female In-season">Target In-Season Female Daily Feed</th>
              <th title="Mating Ratio Target">Target Mating Ratio</th>
              <th title="Cumulative Female Mortality % Target">Target Cumulative Female Mortality %</th>
              <th title="Egg Weight - Target">Target Egg Weight</th>
              <th title="% Hen Week Production Target">Target Hen Production %</th>
              <th title="% Hatchability Target">Target Hatchability Target</th>
              <th title="% Fertility Target">Target Fertility Target</th>
              <th title="Target cumulative total eggs per pullet placed">Target Cumulative Total Pullet Eggs</th>
              <th title="Target Reject Eggs %">Target Reject Eggs %</th>
              <th title="Target Cumulative Number Reject eggs">Target Cumulative Number of Reject Eggs</th>
              <th title="Target Cumulative % reject eggs">Target Cumulative Reject Eggs %</th>
              <th title="Target Hatching Egg Utilization Cumulative %">Target Hatching Egg Utilization Cumulative</th>
              <th title="Target cumulative hatching eggs per pullet placed">Target Cumulative Hatching Eggs per Pullet Placed</th>
              <th title="Target cumulative chicks per pullet placed">Target Cumulative Chicks per Pullet Placed</th>
            </tr>
            </thead>

            <tbody *ngIf="standardHeader">
            <tr *ngFor="let standard of standardHeader.standards">
              <td>{{standard.ageWeeksAtEndOfTheWeeks}}</td>
              <td>{{standard.ageDaysAtEndOfTheWeek}}</td>
              <td>{{standard.maleTargetBodyWeight}}</td>
              <td>{{standard.outOfSeasonFemaleTargetBodyWeight}}</td>
              <td>{{standard.inOfSeasonFemaleTargetBodyWeight}}</td>
              <td>{{standard.maleTargetFeedPerBirdPerDay}}</td>
              <td>{{standard.outOfSeasonFemaleTargetFeedPerBirdPerDay}}</td>
              <td>{{standard.inOfSeasonFemaleTargetFeedPerBirdPerDay}}</td>
              <td>{{standard.targetMatingRatio}}</td>
              <td>{{standard.targetCumulativeFemaleMortality}}</td>
              <td>{{standard.targetEggWeight}}</td>
              <td>{{standard.henWeekProductionTarget}}</td>
              <td>{{standard.hatchabilityTarget}}</td>
              <td>{{standard.fertilityTarget}}</td>
              <td>{{standard.cumulativeNumberRejectEggsTarget}}</td>
              <td>{{standard.rejectEggsTarget}}</td>
              <td>{{standard.cumulativeNumberRejectEggsTarget}}</td>
              <td>{{standard.cumulativePercentageRejectEggsTarget}}</td>
              <td>{{standard.hatchingEggUtilizationCumulativePercentageTarget}}</td>
              <td>{{standard.cumulativeHatchingEggsPerPulletPlacedTarget}}</td>
              <td>{{standard.cumulativeChicksPerPulletPlacedTarget}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>