import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../../util/backend.service';
import {FeedType} from '../feed-type';

@Component({
  selector: 'app-feedType-form',
  templateUrl: './feed-type-form.component.html'
})
export class FeedTypeFormComponent implements OnInit {
  title = 'Add Feed Type';
  feedType!: FeedType;

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  save() {
    if (this.feedType.id) {
      this.backendService.putSilently('feedType', this.feedType).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('feedType', this.feedType).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }
}
