<div class="btn-toolbar mb-2" role="toolbar" aria-label="Toolbar with button groups">
  <div class="form-group col-sm-3">
    <div>
      <div class="label">Standard</div>
    </div>
    <select class="form-control" id="dataTypeToDisplay" name="dataTypeToDisplay"
            [(ngModel)]="dataToDisplay"             (change)="toggle($event.target.value)">
      <option *ngFor="let dataType of availableDataTypes" [value]="dataType.id">{{dataType.value}}</option>
    </select>
  </div>
  <div class="form-group col-sm-3">
    <div>
      <div class="label">From:</div>
    </div>
    <div class="input-group">
      <input id="dateToDisplay" name="dateToDisplay" class="form-control" placeholder="yyyy-mm-dd"
             [(ngModel)]="dateToDisplay" #dateToDisplayDp="ngbDatepicker" (ngModelChange)="filter($event, toDateToDisplay)"
             [footerTemplate]='t'
             ngbDatepicker/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary fas fa-calendar-alt"
                (click)="dateToDisplayDp.toggle()" type="button"></button>
      </div>
      <ng-template #t>
        <button class="btn btn-primary btn-sm m-2 float-left"  (click)="setDateToDisplay(flock.hatchDate); dateToDisplayDp.close()">Hatch Date</button>
        <button class="btn btn-primary btn-sm m-2 float-left"  (click)="setDateToDisplay(today); dateToDisplayDp.close()">Today</button>
        <button class="btn btn-secondary btn-sm m-2 float-right" (click)="dateToDisplayDp.close()">Close</button>
      </ng-template>
    </div>
  </div>
  <div class="form-group col-sm-3">
    <div>
      <div class="label">To:</div>
    </div>
    <div class="input-group">
      <input id="toDateToDisplay" name="toDateToDisplay" class="form-control" placeholder="yyyy-mm-dd"
             [(ngModel)]="toDateToDisplay" #toDateToDisplayDp="ngbDatepicker" (ngModelChange)="filter(dateToDisplay, $event)"
             [footerTemplate]='toT'
             ngbDatepicker/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary fas fa-calendar-alt"
                (click)="toDateToDisplayDp.toggle()" type="button"></button>
      </div>
      <ng-template #toT>
        <button class="btn btn-primary btn-sm m-2 float-left"  (click)="setToDateToDisplay(flock.hatchDate); toDateToDisplayDp.close()">Hatch Date</button>
        <button class="btn btn-primary btn-sm m-2 float-left"  (click)="setToDateToDisplay(today); toDateToDisplayDp.close()">Today</button>
        <button class="btn btn-secondary btn-sm m-2 float-right" (click)="toDateToDisplayDp.close()">Close</button>
      </ng-template>
    </div>
  </div>
  <div class="form-group col-sm-3">
    <br/>
          <button type="button" class="btn btn-primary btn-sm mb-" (click)="setStandards()">
            Set Standards
          </button>
  </div>
</div>

<table class="table table-striped table-bordered">
  <thead>
  <tr>
    <th title="Date">Date</th>
    <th title="Weeks of Age end of the week" data-visible="false">Weeks</th>
    <th title="Days of age end of the week">Days</th>
    <th *ngIf="display('weight')" title="Body weight - Target Male">Target Male Weight (g)</th>
    <th *ngIf="display('weight')" title="Body weight - Target Female Out-of-Season">Target Out-of-Season Female Weight
      (g)
    </th>
    <th *ngIf="display('weight')" title="Body weight - Target Female In-season">Target In-Season Female Weight (g)</th>
    <th *ngIf="display('feed')" title="Target Feed/Bird/Day Male">Target Male Daily Feed</th>
    <th *ngIf="display('feed')" title="Actual Feed/Bird/Day Male">Actual Male Daily Feed</th>
    <th *ngIf="display('feed')" title="Target Feed/Bird/Day Female Out-of-season">Target Out-of-Season Female Daily
      Feed
    </th>
    <th *ngIf="display('feed')" title="Actual Feed/Bird/Day Female Out-of-season">Actual Out-of-Season Female Daily
      Feed
    </th>
    <th *ngIf="display('feed')" title="Target Feed/Bird/Day Female In-season">Target In-Season Female Daily Feed</th>
    <th *ngIf="display('feed')" title="Actual Feed/Bird/Day Female In-season">Actual In-Season Female Daily Feed</th>
    <th *ngIf="display('mating')" title="Mating Ratio Target">Target Mating Ratio</th>
    <th *ngIf="display('mating')" title="Mating Ratio Actual">Actual Mating Ratio</th>
    <th *ngIf="display('mortality')" title="Cumulative Female Mortality % Target">Target Female Mortality %</th>
    <th *ngIf="display('mortality')" title="Cumulative Female Mortality % Actual">Actual Female Mortality %</th>
    <th *ngIf="display('mortality')" title="Cumulative Female Mortality % Target">Target Cumulative Female Mortality %
    </th>
    <th *ngIf="display('mortality')" title="Cumulative Female Mortality % Actual">Actual Cumulative Female Mortality %
    </th>
    <th *ngIf="display('eggWeight')" title="Egg Weight - Target">Target Egg Weight</th>
    <th *ngIf="display('eggWeight')" title="Egg Weight - Actual">Actual Egg Weight</th>
    <th *ngIf="display('henProduction')" title="% Hen Week Production Target">Target Hen Production %</th>
    <th *ngIf="display('henProduction')" title="% Hen Week Production Actual">Actual Hen Production %</th>
    <th *ngIf="display('hatchability')" title="% Hatchability Target">Target Hatchability</th>
    <th *ngIf="display('hatchability')" title="% Hatchability Actual">Actual Hatchability</th>
    <th *ngIf="display('fertility')" title="% Fertility Target">Target Fertility Target</th>
    <th *ngIf="display('fertility')" title="% Fertility Actual">Actual Fertility Target</th>
    <th *ngIf="display('pulletEggs')" title="Target cumulative total eggs per pullet placed">Target Cumulative Total
      Pullet Eggs
    </th>
    <th *ngIf="display('pulletEggs')" title="Actual cumulative total eggs per pullet placed">Actual Cumulative Total
      Pullet Eggs
    </th>
    <th *ngIf="display('rejectedEggs')" title="Target Reject Eggs %">Target Reject Eggs %</th>
    <th *ngIf="display('rejectedEggs')" title="Actual Reject Eggs %">Actual Reject Eggs %</th>
    <th *ngIf="display('rejectedEggs')" title="Target Cumulative Number Reject eggs">Target Cumulative Number of Reject
      Eggs
    </th>
    <th *ngIf="display('rejectedEggs')" title="Actual Cumulative Number Reject eggs">Actual Cumulative Number of Reject
      Eggs
    </th>
    <th *ngIf="display('rejectedEggs')" title="Target Cumulative % reject eggs">Target Cumulative Reject Eggs %</th>
    <th *ngIf="display('rejectedEggs')" title="Actual Cumulative % reject eggs">Actual Cumulative Reject Eggs %</th>
    <th *ngIf="display('hatchability')" title="Target Hatching Egg Utilization Cumulative %">Target Hatching Egg
      Utilization Cumulative
    </th>
    <th *ngIf="display('hatchability')" title="Actual Hatching Egg Utilization Cumulative %">Actual Hatching Egg
      Utilization Cumulative
    </th>
    <th *ngIf="display('hatchability')" title="Target cumulative hatching eggs per pullet placed">Target Cumulative
      Hatching Eggs per Pullet Placed
    </th>
    <th *ngIf="display('hatchability')" title="Actual cumulative hatching eggs per pullet placed">Actual Cumulative
      Hatching Eggs per Pullet Placed
    </th>
    <th *ngIf="display('hatchability')" title="Target cumulative chicks per pullet placed">Target Cumulative Chicks per
      Pullet Placed
    </th>
    <th *ngIf="display('hatchability')" title="Actual cumulative chicks per pullet placed">Actual Cumulative Chicks per
      Pullet Placed
    </th>
  </tr>
  </thead>

  <tbody *ngIf="flockData">
  <tr *ngFor="let data of flockData">
    <td>{{data.date | date: 'dd-MMM-yyyy'}}</td>
    <td>{{data.ageWeeksAtEndOfTheWeeks}}</td>
    <td>{{data.ageDays}}</td>
    <td *ngIf="display('weight')">{{data.maleTargetBodyWeight}}</td>
    <td *ngIf="display('weight')">{{data.outOfSeasonFemaleTargetBodyWeight}}</td>
    <td *ngIf="display('weight')">{{data.outOfSeasonFemaleActualBodyWeight}}</td>
    <td *ngIf="display('weight')">{{data.inOfSeasonFemaleTargetBodyWeight}}</td>
    <td *ngIf="display('weight')">{{data.inOfSeasonFemaleActualBodyWeight}}</td>
    <td *ngIf="display('feed')">{{data.maleTargetFeedPerBirdPerDay}}</td>
    <td *ngIf="display('feed')">{{data.maleActualFeedPerBirdPerDay}}</td>
    <td *ngIf="display('feed')">{{data.outOfSeasonFemaleTargetFeedPerBirdPerDay}}</td>
    <td *ngIf="display('feed')">{{data.outOfSeasonFemaleActualFeedPerBirdPerDay}}</td>
    <td *ngIf="display('feed')">{{data.inOfSeasonFemaleTargetFeedPerBirdPerDay}}</td>
    <td *ngIf="display('feed')">{{data.inOfSeasonFemaleActualFeedPerBirdPerDay}}</td>
    <td *ngIf="display('mating')">{{data.targetMatingRatio}}</td>
    <td *ngIf="display('mating')">{{data.actualMatingRatio}}</td>    
    <td *ngIf="display('mortality')">{{data.targetFemaleMortality | number : '1.3'}}</td>
    <td *ngIf="display('mortality')">{{data.actualFemaleMortality| number : '1.3'}}</td>
    <td *ngIf="display('mortality')">{{data.targetCumulativeFemaleMortality| number : '1.3'}}</td>
    <td *ngIf="display('mortality')">{{data.actualCumulativeFemaleMortality| number : '1.3'}}</td>
    <td *ngIf="display('eggWeight')">{{data.targetEggWeight}}</td>
    <td *ngIf="display('eggWeight')">{{data.actualEggWeight}}</td>
    <td *ngIf="display('henProduction')">{{data.henWeekProductionTarget}}</td>
    <td *ngIf="display('henProduction')">{{data.henWeekProductionActual}}</td>
    <td *ngIf="display('hatchability')">{{data.hatchabilityTarget}}</td>
    <td *ngIf="display('hatchability')">{{data.hatchabilityActual}}</td>
    <td *ngIf="display('fertility')">{{data.fertilityTarget}}</td>
    <td *ngIf="display('fertility')">{{data.fertilityActual}}</td>
    <td *ngIf="display('pulletEggs')">{{data.cumulativeNumberRejectEggsTarget}}</td>
    <td *ngIf="display('pulletEggs')">{{data.cumulativeNumberRejectEggsActual}}</td>
    <td *ngIf="display('rejectedEggs')">{{data.rejectEggsTarget}}</td>
    <td *ngIf="display('rejectedEggs')">{{data.rejectEggsActual}}</td>
    <td *ngIf="display('rejectedEggs')">{{data.cumulativeNumberRejectEggsTarget}}</td>
    <td *ngIf="display('rejectedEggs')">{{data.cumulativeNumberRejectEggsActual}}</td>
    <td *ngIf="display('rejectedEggs')">{{data.cumulativePercentageRejectEggsTarget}}</td>
    <td *ngIf="display('rejectedEggs')">{{data.cumulativePercentageRejectEggsActual}}</td>
    <td *ngIf="display('hatchability')">{{data.hatchingEggUtilizationCumulativePercentageTarget}}</td>
    <td *ngIf="display('hatchability')">{{data.hatchingEggUtilizationCumulativePercentageActual}}</td>
    <td *ngIf="display('hatchability')">{{data.cumulativeHatchingEggsPerPulletPlacedTarget}}</td>
    <td *ngIf="display('hatchability')">{{data.cumulativeHatchingEggsPerPulletPlacedActual}}</td>
    <td *ngIf="display('hatchability')">{{data.cumulativeChicksPerPulletPlacedTarget}}</td>
    <td *ngIf="display('hatchability')">{{data.cumulativeChicksPerPulletPlacedActual}}</td>
  </tr>
  </tbody>
</table>