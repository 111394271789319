<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light" *ngIf="flocks">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Flocks</h1>
            </div>
          </div>
        </div>
        <div class="card-body">

          <button type="button" class="btn btn-primary btn-sm mb-" (click)="add()">
            Add
          </button>
          <table class="table table-striped table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Code</th>
              <th>Breed</th>
              <th>Farm</th>
              <th>House</th>
              <th>Hatch Date</th>
              <th>End of 17th Week</th>
              <th>Date Moved</th>
              <th>End of 22nd Week</th>
              <th>Date 64 Weeks</th>
              <th>Females at Placement</th>
              <th>Males at Placement</th>
              <th>Females at Start of Week 22</th>
              <th>Males at Start of Week 22</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody *ngIf="flocks">
            <tr *ngFor="let flock of flocks">
              <td>{{flock.code}}</td>
              <td>{{flock.breed}}</td>
              <td>{{flock.farm}}</td>
              <td>{{flock.house}}</td>
              <td>{{flock.hatchDate | date : 'dd MMM yyyy'}}</td>
              <td>{{flock.endOfWeek17 | date : 'dd MMM yyyy'}}</td>
              <td>{{flock.dateMoved | date : 'dd MMM yyyy'}}</td>
              <td>{{flock.endOfWeek22 | date : 'dd MMM yyyy'}}</td>
              <td>{{flock.dateOfWeek64 | date : 'dd MMM yyyy'}}</td>
              <td>{{flock.femalesAtPlacement}}</td>
              <td>{{flock.malesAtPlacement}}</td>
              <td>{{flock.femalesAtStartOfWeek22}}</td>
              <td>{{flock.malesAtStartOfWeek22}}</td>
              <td>
                <button type="button" class="btn btn-link" title="Edit"
                        (click)="edit(flock)">
                  <span class="fas fa-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-link" title="Details"
                        (click)="details(flock)">
                  <span class="fas fa-eye" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>