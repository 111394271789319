import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {BodyWeight} from "../body-weight";

@Component({
  selector: 'app-body-weight-form',
  templateUrl: './body-weight-form.component.html'
})
export class BodyWeightFormComponent implements OnInit {
  bodyWeight!: BodyWeight;
  houses!: House[];
  selectedHouse = {} as House;
  bodyWeightDate: any;
  title!: string;
  defaultBodyWeightDate = new Date();
  farmId!: string;

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal,
              private authService: AuthService) {
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.defaultBodyWeightDate.setFullYear(this.defaultBodyWeightDate.getFullYear());
    if (this.bodyWeight.date) {
      this.bodyWeightDate = new Date(this.bodyWeight.date);
    } else {
      this.bodyWeightDate = this.defaultBodyWeightDate;
    }
    if (this.bodyWeight.id) {
      this.selectedHouse = {id: this.bodyWeight.houseId, name: this.bodyWeight.house} as House;
      this.houses = [this.selectedHouse];
    } else {
      this.bodyWeight.gender = 'Female';
      this.houses = await this.backendService.get('house/withActiveFlock/' + this.farmId);
    }
  }

  save() {
    this.bodyWeight.date = this.bodyWeightDate.getTime();
    if (this.bodyWeight.id) {
      this.backendService.putSilently('captureBodyWeight', this.bodyWeight).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {

      });
    } else {
      this.backendService.postSilently('captureBodyWeight', this.bodyWeight).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  async getActiveHouses() {
    this.houses = await this.backendService.get(`house/withActiveFlock/${this.bodyWeightDate.getTime()}/${this.farmId}`);    
  }
}
