import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {EggCollection} from '../egg.collection';
import {EmployeeFormComponent} from '../../farm/employee/employee-form/employee-form.component';
import {Employee} from '../../farm/employee/employee';
import {ModalService} from '../../util/modal.service';
import {EggCollectionFormComponent} from '../egg-collection-form/egg-collection-form.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-egg-collection-list',
  templateUrl: './egg-collection-list.component.html',
  styleUrls: ['./egg-collection-list.component.css']
})
export class EggCollectionListComponent implements OnInit {
  houses!: House[];
  collectionDate = new Date();
  collectionHouseId = '';
  collections!: EggCollection[];
  private farmId!: string;

  constructor(public authService: AuthService,
              private backendService: BackendService,
              private datePipe: DatePipe,
              private modalService: ModalService) {
  }

  ionViewWillEnter() {
    this.ngOnInit();
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.houses = await this.backendService.get('house/' + this.farmId);
    await this.getCollections();
  }

  async getCollections() {
    const selectedDate = this.collectionDate.getTime();
    this.collections = await this.backendService.postSilently('collection',
      {houseId: this.collectionHouseId, date: selectedDate});
  }

  status(status: string) {
    switch (status) {
      case 'Pending':
        return 'text-warning';
      case 'Confirmed':
        return 'text-success';
      case 'Exception':
        return 'text-danger';
    }
    return 'text-info';
  }

  async add() {
    const modalRef = this.modalService.openRef(EggCollectionFormComponent, 'lg');
    const component = modalRef.componentInstance as EggCollectionFormComponent;
    component.title = 'Add Collection ';
    component.collection = new EggCollection();
    await modalRef.result;
    this.getCollections();
  }

  async edit(collection: EggCollection) {
    const modalRef = this.modalService.openRef(EggCollectionFormComponent, 'lg');
    const component = modalRef.componentInstance as EggCollectionFormComponent;
    component.title = 'Edit Collection ';
    component.collection = collection;
    await modalRef.result;
    this.getCollections();
  }

  async delete(collection: EggCollection) {
    this.modalService.confirm('Deleting a collection (' + collection.house + this.datePipe.transform(this.collectionDate, ' - HH:mm')
      + ') will erase it from the system. Are you sure you want to delete this collection?' )
      .then( response => {
        if (response){
          this.backendService.delete('collection/' + collection.id).then( response => {
            this.modalService.info('Collection successfully deleted');
            this.getCollections();
          });
        }
        }, errObj => {
        this.modalService.error('Error occured while deleting the collection.');
        }
      );
  }
}
