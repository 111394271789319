<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Mortality Chart</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row align-items-center">
            <div class="form-group  mx-sm-3 mb-2">
              <label for="mortalityFromDate">From</label>
              <div class="input-group">
                <input id="mortalityFromDate" name="mortalityFromDate" class="form-control" placeholder="dd-mm-yyyy"
                       [(ngModel)]="mortalityFromDate" #mortalityFromDateDp="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="mortalityFromDateDp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="mortalityToDate">From</label>
              <div class="input-group">
                <input id="mortalityToDate" name="mortalityToDate" class="form-control" placeholder="dd-mm-yyyy"
                       [(ngModel)]="mortalityToDate" #mortalityToDateDp="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="mortalityToDateDp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mx-sm-3 mb-2">
              <label for="house">House</label>
              <select class="form-control" id="house" name="site"
                      [(ngModel)]="houseId">
                <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
              </select>
            </div>
            <div class="form-group mx-sm-3 mb-2 ">
              <button type="submit" class="btn-sm btn-primary mx-sm-3  mb-2" (click)="search()" 
                      ngbAutofocus>
                Search
              </button>
            </div>
          </div>
          <div style="display: block;">
            <canvas baseChart width="400" height="100"
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    [plugins]="lineChartPlugins">
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>