import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { ModalService } from './modal.service';
import {AuthService} from '../auth/auth.service';

export interface BackendOptions {
  headers?: { [header: string]: string | string[] };
  params?: { [param: string]: string | string[] };
}

@Injectable({ providedIn: 'root' })
export class BackendService {
  busyCount = 0;

  constructor(private http: HttpClient, private modalService: ModalService, private authService: AuthService) {}

  get busy() {
    return this.busyCount > 0;
  }


  private addToken(options?: BackendOptions) {
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = {
        'Authorization': `Bearer ${this.authService.getToken()}`
      };
    }
    return options;
  }

  public async get<T>(
    path: string,
    parameters?: { [param: string]: string | string[] }
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      let options = {params: parameters} as BackendOptions;
      options = this.addToken(options);
      return await this.http
        .get<T>(environment.apiUrl + path, options)
        .toPromise();
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }


  public async delete<T>(
    path: string,
    parameters?: { [param: string]: string | string[] }
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      let options = {params: parameters} as BackendOptions;
      options = this.addToken(options);
      return await this.http
        .delete<T>(environment.apiUrl + path, options)
        .toPromise();
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async post<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.postInternal(true, path, body, options);
  }

  public async postSilently<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.postInternal(false, path, body, options);
  }

  private async postInternal<T>(
    displaySuccess: boolean,
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      options = this.addToken(options);
      const response = await this.http
        .post<T>(environment.apiUrl + path, body, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async put<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.putInternal(true, path, body, options);
  }

  public async putSilently<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.putInternal(false, path, body, options);
  }

  private async putInternal<T>(
    displaySuccess: boolean,
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      options = this.addToken(options);
      const response = await this.http
        .put<T>(environment.apiUrl + path, body, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  public async patch<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.patchInternal(true, path, body, options);
  }

  public async patchSilently<T>(
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    return this.patchInternal(false, path, body, options);
  }

  private async patchInternal<T>(
    displaySuccess: boolean,
    path: string,
    body: any,
    options?: BackendOptions
  ): Promise<T> {
    await Promise.resolve(); // prevent ExpressionChangedAfterItHasBeenCheckedError
    this.busyCount++;

    try {
      const response = await this.http
        .patch<T>(environment.apiUrl+ path, body, options)
        .toPromise();

      if (displaySuccess) {
        this.displaySuccess('Successfully completed');
      }
      return response;
    } catch (e) {
      this.handleError(e);
      throw e; // Rethrow to abort the caller
    } finally {
      this.busyCount--;
    }
  }

  private handleError(e: Error) {
    if (e instanceof HttpErrorResponse && e.status === 401) {
      // User has been logged out, refresh at login screen
      this.authService.logout();
      window.location.reload();
    } else {
      this.displayError(e);
    }
  }

  private displaySuccess(message: string) {
    const messageModal = this.modalService.openComponent(MessageModalComponent);
    messageModal.successMessage = message;
  }

  private displayError(e: Error) {
    const messageModal = this.modalService.openComponent(MessageModalComponent);
    messageModal.error = e;
  }
}
