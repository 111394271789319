<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>Please Confirm</h4>
</div>

<div class="modal-body">
  <div class="alert alert-warning" role="alert">
    {{ message }}
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-lg btn-primary"
    (click)="activeModal.close(true)"
  >
    Yes
  </button>
  <button
    type="button"
    class="btn btn-lg btn-secondary"
    (click)="activeModal.close(false)"
    ngbAutofocus
  >
    No
  </button>
</div>
