import {Component, OnInit} from '@angular/core';
import {StandardImport} from '../standard-import';
import {BackendService} from '../../util/backend.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-standard-view',
  templateUrl: './standard-view.component.html',
  styleUrls: ['./standard-view.component.css']
})
export class StandardViewComponent implements OnInit {

  standardHeader!: StandardImport;
  constructor(private backendService: BackendService,
              private route: ActivatedRoute) { }

  async ngOnInit() {
    const standardHeaderId = this.route.snapshot.params.id;
    this.standardHeader = await this.backendService.get('standard/' + standardHeaderId);
  }

}
