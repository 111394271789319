<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>Set Flock Standards</h4>
</div>

<div class="modal-body">

  <form #flockStandardForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Flock Code</label>
      </div>
      <div class="form-group col-sm-6">
        {{flock.code}}
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Breed</label>
      </div>
      <div class="form-group col-sm-6">{{flock.breed}}</div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>House</label>
      </div>
      <div class="form-group col-sm-6">{{flock.house}}</div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Mortality Standards</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="standards" name="standards"
                [(ngModel)]="flock.mortalityStandardHeaderId">
          <option *ngFor="let standard of mortalityStandards" [value]="standard.id">{{standard.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Feed Standards</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="feed" name="feed"
                [(ngModel)]="flock.feedStandardHeaderId">
          <option *ngFor="let standard of feedStandards" [value]="standard.id">{{standard.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Body Weight Standards</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="bodyWeight" name="bodyWeight"
                [(ngModel)]="flock.bodyWeightStandardHeaderId">
          <option *ngFor="let standard of bodyWeightStandards" [value]="standard.id">{{standard.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Temperature Standards</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="temperature" name="temperature"
                [(ngModel)]="flock.temperatureStandardHeaderId">
          <option *ngFor="let standard of temperatureStandards" [value]="standard.id">{{standard.name}}</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!flockStandardForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>