import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../../util/backend.service';
import {FeedType} from '../feed-type';
import {FeedTypeFormComponent} from '../feed-type-form/feed-type-form.component';
import {ModalService} from '../../../util/modal.service';

@Component({
  selector: 'app-feed-type-list',
  templateUrl: './feed-type-list.component.html'
})
export class FeedTypeListComponent implements OnInit {

  feedTypes!: FeedType[];
  constructor(private backendService: BackendService,
              private modalService: ModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.feedTypes = await this.backendService.get('feedType/');
  }

  async edit(feedType: FeedType){
    const modalRef = this.modalService.openRef(FeedTypeFormComponent);
    const component = modalRef.componentInstance as FeedTypeFormComponent;
    component.feedType = feedType;
    component.title = 'Edit ' + feedType.name;
    await modalRef.result;
    this.load();
  }

  async add(){
    const modalRef = this.modalService.openRef(FeedTypeFormComponent);
    const component = modalRef.componentInstance as FeedTypeFormComponent;
    component.title = 'Add Feed Type ';
    component.feedType = { } as FeedType;
    await modalRef.result;
    this.load();
  }
}
