<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light" *ngIf="flock">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h3 class="card-title text-centre">{{flock.code}} Details</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dl class="row">
            <dt class="col-md-1">Farm</dt>
            <dd class="col-md-2 colomnleftTextAlign">
              {{flock.farm}}
            </dd>
            <dt class="col-md-1">House</dt>
            <dd class="col-md-2 colomnleftTextAlign">
              {{flock.house}}
            </dd>
            <dt class="col-md-2">Floor Area</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.floorAreaSquare}} m <sup>2</sup>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">No. of Females at placement</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femalesAtPlacement}}
            </dd>
            <dt class="col-md-2">No. of Males at placement</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.malesAtPlacement}}
            </dd>
            <dt class="col-md-2">Total Birds at placement</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.malesAtPlacement + flock.femalesAtPlacement}}
            </dd>
            <dt class="col-md-2">Mating Ratio at placement</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femalesAtPlacement / flock.malesAtPlacement | number : '1.2-2'}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">No. of Females at start of week 22</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femalesAtStartOfWeek22}}
            </dd>
            <dt class="col-md-2">No. of Males at start of week 22</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.malesAtStartOfWeek22}}
            </dd>
            <dt class="col-md-2">Total Birds at start of week 22</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.malesAtStartOfWeek22 + flock.femalesAtStartOfWeek22}}
            </dd>
            <dt class="col-md-2">Mating Ratio at start of week 22</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femalesAtStartOfWeek22 / flock.malesAtStartOfWeek22 | number : '1.2-2'}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Current No. of Females</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.currentFemales}}
            </dd>
            <dt class="col-md-2">Current No. of Males</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.currentMales}}
            </dd>
            <dt class="col-md-2">Current Total Birds</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.totalBirds}}
            </dd>
            <dt class="col-md-2">Current Mating Ratio</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.matingRatio| number : '1.2-2'}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Female Stocking Density at start of week 22</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femalesAtStartOfWeek22 / flock.floorAreaSquare| number : '1.2-2'}} per m <sup>2</sup>
            </dd>
            <dt class="col-md-2">Female and Males Stocking Density at start of week 22</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{(flock.femalesAtStartOfWeek22 + flock.malesAtStartOfWeek22) / flock.floorAreaSquare| number : '1.2-2'}}
              per m <sup>2</sup>
            </dd>
            <dt class="col-md-2">Current Female Stocking Density</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femaleStockingDensity| number : '1.2-2'}} per m <sup>2</sup>
            </dd>
            <dt class="col-md-2">Current Female and Males Stocking Density</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femaleAndMaleStockingDensity| number : '1.2-2'}} per m <sup>2</sup>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Total Length Female Feeder</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femaleFeederLength | number : '1.2-2'}} m
            </dd>
            <dt class="col-md-2">Feeders Space/female</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.feedersSpacePerFemale| number : '1.2-2'}} mm/bird
            </dd>
            <dt class="col-md-2">Total Drinker Space</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.birdsPerDrinker| number : '1.2-2'}} drinkers
            </dd>
            <dt class="col-md-2">Drinker space</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.birdsPerDrinker| number : '1.2-2'}} birds/drinker
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Nest Box Holes</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.numberOfNestBoxHoles| number : '1.2-2'}}
            </dd>
            <dt class="col-md-2">Females per nest</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.femalesPerNest| number : '1.2-2'}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Hatch Date</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.hatchDate| date: 'dd-MMM-yyyy'}}
            </dd>
            <dt class="col-md-2">End of 17<sup>th</sup> Week</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.endOfWeek17| date: 'dd-MMM-yyyy'}}
            </dd>
            <dt class="col-md-2">End of 22<sup>nd</sup> Week</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.endOfWeek22| date: 'dd-MMM-yyyy'}}
            </dd>
            <dt class="col-md-2">Date of 64 weeks</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.dateOfWeek64| date: 'dd-MMM-yyyy'}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Depletetion Date</dt>
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.dateDepleted| date: 'dd-MMM-yyyy'}}
            </dd>
            <dt class="col-md-2">Number depleted
            <dd class="col-md-1 colomnleftTextAlign">
              {{flock.numberDepleted}}
            </dd>
          </dl>

          <app-flock-data [flockId]="flock.id"></app-flock-data>
        </div>
      </div>
    </div>
  </div>
</section>