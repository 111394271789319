<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">
  <form #temperatureForm="ngForm" (ngSubmit)="save()" *ngIf="temperature">
    <div class="form-row row">
      <div class="form-group col">
        <label for="house">House</label>
        <app-drop-down id="house" name="house" required stretch="true" [disabled]="temperature.id"
                       [(ngModel)]="temperature.houseId">
          <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="temperatureDate">Date</label>
        <div class="input-group">
          <input id="temperatureDate" name="temperatureDate" class="form-control" placeholder="dd-mm-yyyy"
                 (ngModelChange)="getActiveHouses()"
                 [(ngModel)]="temperatureDate" #temperatureDatePicker="ngbDatepicker" [readOnly]="temperature.id"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt" [disabled]="temperature.id"
                    (click)="temperatureDatePicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="min">Minimum Temperature</label>
        <app-text-input type="number" id="min"
                        name="min" [(ngModel)]="temperature.min" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="max">Maximum Temperature</label>
        <app-text-input type="number" id="max"
                        name="max" [(ngModel)]="temperature.max" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!temperatureForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>