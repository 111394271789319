<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #eggCollectionForm="ngForm" (ngSubmit)="save()">

    <div class="row">
      <div class="col-6">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>House</label>
          </div>
          <div class="form-group col-sm-6">
            <select class="form-control" id="house" name="house"
                    [(ngModel)]="collection.houseId">
              <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Date</label>
          </div>
          <div class="form-group col-sm-6">
            <div class="input-group">
              <input id="startDate" name="startDate" class="form-control" placeholder="yyyy-mm-dd"
                     [(ngModel)]="collectionDate" #collectiondatePicker="ngbDatepicker"
                     ngbDatepicker/>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary fas fa-calendar-alt"
                        (click)="collectiondatePicker.toggle()" type="button"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 span2">
        <div class="form-row">
          <div class="form-group col-sm-3 my-auto">
            <label>Time</label>
          </div>
          <div class="form-group col-sm-6">
            <ngb-timepicker [minuteStep]="15" name="collectionTime" [(ngModel)]="collectionTime"></ngb-timepicker>
          </div>
        </div>
      </div>
      <div class="col-6">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Nest Box</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="nestBox" [(ngModel)]="collection.nestBox" appMin="0"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Jumbo</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="jumbo" [(ngModel)]="collection.jumbo" appMin="0"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>M/Shape</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="mShape" [(ngModel)]="collection.mShape" appMin="0"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Dirties</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="dirties" [(ngModel)]="collection.dirties" appMin="0"
                            stretch="true" required></app-text-input>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Floor</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="floor" [(ngModel)]="collection.floor" appMin="0"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Soft Shell</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="softShell" [(ngModel)]="collection.softShell"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Small</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="small" [(ngModel)]="collection.small"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Cracks</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="cracks" [(ngModel)]="collection.cracks"
                            stretch="true" required></app-text-input>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Broken</label>
          </div>
          <div class="form-group col-sm-6">
            <app-text-input type="number" name="broken" [(ngModel)]="collection.broken"
                            stretch="true" required></app-text-input>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        Total Rejects <span class="text-danger">{{totalRejected}}</span>
        Total Eggs <span class="text-success">{{totalEggs}}</span>
      </div>
      <button type="submit" class="btn btn-primary right"
              [disabled]="!eggCollectionForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>