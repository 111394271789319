import { Component, OnInit } from '@angular/core';
import {House} from '../house';
import {Site} from '../../site/site';
import {BackendService} from '../../../util/backend.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-house-form',
  templateUrl: './house-form.component.html',
  styleUrls: ['./house-form.component.css']
})
export class HouseFormComponent implements OnInit {
  house!: House;
  title!: string;
  sites!: Site[];

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal) { }

  async ngOnInit() {
    this.sites = await this.backendService.get('site/' + this.house.farmId);
  }

  save() {
    if (this.house.id) {
      this.backendService.putSilently('house', this.house).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('house', this.house).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }
}
