import {
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { SimpleValueAccessor } from './simple-value-accessor';

@Component({
  selector: 'app-text-input',
  template: `
    <!--suppress TypeScriptUnresolvedVariable -->
    <span *ngIf="currencySymbol" class="mr-1" style="line-height: 36px;">
      {{ currencySymbol }}
    </span>
    <!-- The name attribute is set on the input so that CzFixture.valuesOfInputs() works -->
    <input
      [type]="type"
      class="form-control"
      style="height: 36px;"
      [ngStyle]="{ width: computedWidth }"
      #input
      autocomplete="off"
      [attr.name]="name"
      (input)="onChange($event.target.value.trim())"
      (blur)="onTouched()"
      [disabled]="disabled"
    />
    <app-control-errors
      [ngClass]="computedWidth === '100%' ? [] : ['ml-2']"
      [control]="controlDirective.control"
      [customErrors]="customErrors"
    >
    </app-control-errors>
  `,
  styles: [':host {display: flex; flex-wrap: wrap}'],
})
export class TextInputComponent extends SimpleValueAccessor {
  @Input() type = 'text';
  @Input() name?: string;
  @Input() customErrors = {};
  @Input() stretch = false;
  @Input() width = '200px';
  @Input() currencySymbol?: string;
  @ViewChild('input') input!: ElementRef;

  constructor(@Optional() @Self() controlDirective: NgControl) {
    super(controlDirective);
  }

  get computedWidth() {
    return this.stretch ? '100%' : this.width;
  }

  writeValue(value: any) {
    // 0 should still be set as 0
    this.input.nativeElement.value =
      value === undefined || value === null ? '' : value;
  }
}
