import { Component, OnInit } from '@angular/core';
import {Business} from '../../business';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../../util/backend.service';
import {Site} from '../site';

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.css']
})
export class SiteFormComponent implements OnInit {
  title = 'Add Site';
  site!: Site;

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  save() {
    if (this.site.id) {
      this.backendService.putSilently('site', this.site).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('site', this.site).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }
}
