import {Component, Input, OnInit} from '@angular/core';
import {SiteFormComponent} from '../../site/site-form/site-form.component';
import {Site} from '../../site/site';
import {BackendService} from '../../../util/backend.service';
import {ModalService} from '../../../util/modal.service';
import {House} from '../house';
import {HouseFormComponent} from '../house-form/house-form.component';

@Component({
  selector: 'app-house-list',
  templateUrl: './house-list.component.html',
  styleUrls: ['./house-list.component.css']
})
export class HouseListComponent implements OnInit {

  @Input() farmId!: string;
  houses!: House[];
  constructor(private backendService: BackendService,
              private modalService: ModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.houses = await this.backendService.get('house/' + this.farmId);
  }

  async edit(house: House){
    const modalRef = this.modalService.openRef(HouseFormComponent);
    const component = modalRef.componentInstance as HouseFormComponent;
    component.house = house;
    component.title = 'Edit ' + house.name;
    await modalRef.result;
    this.load();
  }

  async add(){
    const modalRef = this.modalService.openRef(HouseFormComponent);
    const component = modalRef.componentInstance as HouseFormComponent;
    component.title = 'Add Site ';
    component.house = { farmId: this.farmId} as House;
    await modalRef.result;
    this.load();
  }

}
