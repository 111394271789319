<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Body Weight Standards</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <p> To import body weight standards do the following: </p>
          <ol>
            <li>Download the Body Weight Standards Template <a href="{{templateUrl}}">here.</a></li>
            <li>Update the template with the standards, do not change the column names.</li>
            <li>Upload the updated standards csv file below.</li>
          </ol>

          <form class="form-inline" #importForm="ngForm">
            <label class="sr-only" for="name">Name</label>
            <input type="text" [(ngModel)]="standardImport.name" name="name"
                   class="form-control mr-sm-2" id="name" placeholder="YYYY standards name" required>
            <div class="custom-file col-sm-6 mr-1">
              <input type="file" id="file"
                     (change)="uploadFiles($event.target.files)" class="custom-file-input" required>
              <label for="file" class="custom-file-label">Choose file</label>
            </div>
            <button type="button" class="btn btn-primary" (click)="import()" [disabled]="importForm.invalid">
              Import
            </button>
          </form>
          <table class="table table-striped table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Date Imported</th>
              <th>Farm</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody *ngIf="standards">
            <tr *ngFor="let standard of standards">
              <td>{{standard.dateTimeInserted | date : 'dd MMM yyyy hh:mm'}}</td>
              <td>{{standard.farm}}</td>
              <td>{{standard.name}}</td>
              <td>{{standard.status}}</td>
              <td>
                <button type="button" class="btn btn-link" title="Details"
                        routerLink="/body-weight/standard/view/{{standard.id}}">
                  <span class="fas fa-eye" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>