import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {version} from '../../../environments/version';
import {from} from 'rxjs';
import {CredentialsDto} from '../credentialsDto';
import {ModalService} from '../../util/modal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  readonly VERSION = version;
  loginError = '';
  credentials = {cellnumber: '', pin: ''} as CredentialsDto;
  loginResult = '';

  constructor(private  authService: AuthService,
              private  router: Router,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.credentials = {} as CredentialsDto;
  }

  login() {
    this.authService.login(this.credentials).subscribe((res) => {
      this.router.navigateByUrl('home');
    }, (err) => {
      this.loginError = err.error;
    });
  }

  async forgotPass() {
    if (this.credentials.cellnumber) {
      await this.modalService
        .confirm('You are about to reset your pin. Are you sure you want to reset your pin?')
        .then(result => {
          this.authService.resetPin(this.credentials).subscribe((res: any) => {
              this.loginResult = res.payload;
            },
            (err) => {
              this.loginError = err.error;
            });
        }, err => {
          this.loginError = err.error;
        });
    } else {
      await this.modalService.error('Please enter a valid cell number to reset your pin.');
    }
  }
}
