<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">

      <button type="button" class="btn btn-primary btn-sm mb-1" (click)="add()">
        Add
      </button>

      <table class="table table-striped table-bordered" style="margin-top: 10px">
        <thead>
        <tr>
          <th>Date Created</th>
          <th>Code</th>
          <th>Name</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody *ngIf="sites">
        <tr *ngFor="let site of sites">
          <td>{{site.dateTimeInserted | date:'dd MMM yyyy'}}</td>
          <td>{{site.code}}</td>
          <td>{{site.name}}</td>
          <td>
            <button type="button" class="btn btn-link" title="End User Session" (click)="edit(site)">
              <span class="fa fa-edit" aria-hidden="true"></span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</section>
