import {Component, OnInit} from "@angular/core";
import {BackendService} from "../../util/backend.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {StandardImport} from "../../standard/standard-import";
import {MortalityStandard} from "../mortality-standard/mortality.standard";

@Component({
  selector: 'app-mortality-standard-view',
  templateUrl: './mortality-standard-view.component.html'
})
export class MortalityStandardViewComponent implements OnInit {

  mortalityStandards!: MortalityStandard[];
  standardImport!: StandardImport;
  
  constructor(private backendService: BackendService,
              private route: ActivatedRoute) { }

  async ngOnInit() {
    const standardHeaderId = this.route.snapshot.params.id;
    this.standardImport = await this.backendService.get('standard/' + standardHeaderId);
  }

}