<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1 *ngIf="standardImport">{{standardImport.farm}} {{standardImport.name}} Standards</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th title="Weeks of Age end of the week">Weeks</th>
              <th title="Days of age end of the week">Days</th>
              <th title="Standard minimum temperature">Standard minimum</th>
              <th title="Standard maximum temperature">Standard maximum</th>
            </tr>
            </thead>

            <tbody *ngIf="standardImport">
            <tr *ngFor="let standard of standardImport.standards">
              <td>{{standard.week}}</td>
              <td>{{standard.flockDay}}</td>
              <td>{{standard.minimum}}</td>
              <td>{{standard.maximum}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>