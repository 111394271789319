<div class="container">
  <div class="row">
    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <div class="logo card-title fadeInDown text-center">
            <img src="assets/images/logo.png"
                 style="width:220px;"
                 alt="Arbor Acres Logo" title="Arbor Acres"/>
            <h3 class="text-center font-weight-bold text-nowrap">Parent Stock Management System</h3>
            <h5 class="text-centre">Logon</h5>
          </div>
          <form #thisForm="ngForm" (submit)="login()" class="form-signin" autocomplete="off">
            <div class="form-group row">
                <label for="cellnumber" class="col-md-3 col-form-label">Cellnumber</label>
              <app-text-input class="col-md-9" id="cellnumber" stretch="true" name="cellphone-number"
                              placeholder="0712345678"
                              autocomplete="new-cellnumber-number"
                              [(ngModel)]="credentials.cellnumber" required autocomplete>
              </app-text-input>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="pin-number">Pin</label>
              <app-password-input class="col-md-9" id="pin-number" stretch="true" name="pin-number"
                                  placeholder="Pin"
                                  inputmode="numeric"
                                  minlength=4 maxlength=4
                                  autocomplete="new-pin-number" [(ngModel)]="credentials.pin">
              </app-password-input>
            </div>

            <div class="form-group">
              <p *ngIf="loginError" class="text-danger text-center">
                {{ loginError }}
              </p>
              <p *ngIf="loginResult" class="text-success text-center">
                {{ loginResult }}
              </p>

              <div class="col text-center mt-1 mb-1">
                <a (click)="forgotPass()" class=" text-right ">
                  <strong translate>Forgot Pin</strong>
                </a>
              </div>
              <button type="submit" class="btn btn-lg btn-primary btn-block text-uppercase">
                Logon
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
