import {Component, OnInit} from '@angular/core';
import {Flock} from '../flock';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../util/backend.service';
import {House} from '../../farm/house/house';
import {StandardImport} from '../../standard/standard-import';

@Component({
  selector: 'app-flock-standard-form',
  templateUrl: './flock-standard-form.component.html'
})
export class FlockStandardFormComponent implements OnInit {

  title = 'Add Flock';
  flock!: Flock;
  flockHatchDate = new Date();
  flockDateMoved = new Date();
  houses!: House[];
  mortalityStandards!: StandardImport[];
  feedStandards!: StandardImport[];
  bodyWeightStandards!: StandardImport[];
  temperatureStandards!: StandardImport[];
  farmId!: string;

  constructor(
    private backendService: BackendService,
    public activeModal: NgbActiveModal) { }

  async ngOnInit() {
    if (this.flock.id){
      this.flockDateMoved = new Date(this.flock.dateMoved);
      this.flockHatchDate = new Date(this.flock.hatchDate);
    }
    this.houses = await this.backendService.get('house/' + this.farmId);
    let allActiveStandards = await this.backendService.get<StandardImport[]>('standard/findActive/All');
    this.mortalityStandards = allActiveStandards.filter(s => s.metric === 'Mortality');
    this.feedStandards = allActiveStandards.filter(s => s.metric === 'Feed');
    this.bodyWeightStandards = allActiveStandards.filter(s => s.metric === 'Body Weight');
    this.temperatureStandards = allActiveStandards.filter(s => s.metric === 'Temperature');
  }

  save() {
    this.backendService.putSilently('flock/standard', this.flock).then((res: any) => {
      this.activeModal.close(res);
    }, (err: any) => {

    });
  }
}
