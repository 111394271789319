<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Flock Code</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="code" [(ngModel)]="flock.code" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Breed</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="name" [(ngModel)]="flock.breed" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>House</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="site" name="site"
                [(ngModel)]="flock.houseId">
          <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Standards</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="standards" name="standards"
                [(ngModel)]="flock.standardHeaderId">
          <option *ngFor="let standard of standards" [value]="standard.id">{{standard.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label for="flockHatchDate">Hatch Date</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input id="flockHatchDate" name="flockHatchDate" class="form-control" placeholder="dd/mm/yyyy"
                 [(ngModel)]="flockHatchDate" #flockHatchDatePicker="ngbDatepicker"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt"
                    (click)="flockHatchDatePicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label for="flockDateMoved">Date Moved</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input id="flockDateMoved" name="flockDateMoved" class="form-control" placeholder="dd/mm/yyyy"
                 [(ngModel)]="flockDateMoved" #flockDateMovedPicker="ngbDatepicker"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt"
                    (click)="flockDateMovedPicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-8">
        <label>Number of Females at placement</label>
      </div>
      <div class="form-group col-sm-4">
        <app-text-input type="number" name="femalesAtPlacement" [(ngModel)]="flock.femalesAtPlacement"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-8">
        <label>Number of Males at placement</label>
      </div>
      <div class="form-group col-sm-4">
        <app-text-input type="number" name="malesAtPlacement" [(ngModel)]="flock.malesAtPlacement"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-8">
        <label>Number of Females at start of week 22</label>
      </div>
      <div class="form-group col-sm-4">
        <app-text-input type="number" name="femalesAtWeek22" [(ngModel)]="flock.femalesAtStartOfWeek22"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-8">
        <label>Number of Males at start of week 22</label>
      </div>
      <div class="form-group col-sm-4">
        <app-text-input type="number" name="malesAtStartOfWeek22" [(ngModel)]="flock.malesAtStartOfWeek22"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>