<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">
  <form #feedForm="ngForm" (ngSubmit)="save()" *ngIf="feed">
    <div class="form-row row">
      <div class="form-group col">
        <label for="house">House</label>
        <app-drop-down id="house" name="house" required stretch="true" [disabled]="feed.id"
                       [(ngModel)]="feed.houseId">
          <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="gender">Gender</label>
        <app-drop-down id="gender" name="gender" required stretch="true" [disabled]="feed.id"
                       [(ngModel)]="feed.gender">
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="feedDate">Date</label>
        <div class="input-group">
          <input id="feedDate" name="feedDate" class="form-control" placeholder="dd-mm-yyyy"
                 (ngModelChange)="getActiveHouses()"
                 [(ngModel)]="feedDate" #feedDatePicker="ngbDatepicker" [readOnly]="feed.id"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt" [disabled]="feed.id"
                    (click)="feedDatePicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-4">
        <label for="house">House</label>
        <app-drop-down id="fed" name="feed" required stretch="true"
                       [(ngModel)]="feed.feedTypeId">
          <option *ngFor="let feedType of feedTypes" [value]="feedType.id">{{feedType.name}}</option>
        </app-drop-down>
      </div>
      <div class="form-group col-4">
        <label for="actual">Feed g/b/d</label>
        <app-text-input type="number" id="actual"
                        name="actual" [(ngModel)]="feed.actual" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-4">
        <label for="actualWater">Water ml/b/d</label>
        <app-text-input type="number" id="actualWater"
                        name="actualWater" [(ngModel)]="feed.actualWater" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!feedForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>