import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginPage} from './auth/login/login.page';
import {FarmListComponent} from './farm/farm-list/farm-list.component';
import {FarmViewComponent} from './farm/farm-view/farm-view.component';
import {SiteListComponent} from './farm/site/site-list/site-list.component';
import {HouseListComponent} from './farm/house/house-list/house-list.component';
import {EmployeeListComponent} from './farm/employee/employee-list/employee-list.component';
import {FlockListComponent} from './flock/flock-list/flock-list.component';
import {StandardListComponent} from './standard/standard-list/standard-list.component';
import {StandardViewComponent} from './standard/standard-view/standard-view.component';
import {FlockViewComponent} from './flock/flock-view/flock-view.component';
import {FlockDataComponent} from './flock/flock-data/flock-data.component';
import {EggCollectionListComponent} from './egg-collection/egg-collection-list/egg-collection-list.component';
import {MortalityStandardListComponent} from './mortality/mortality-standard/mortality-standard-list.component';
import {MortalityStandardViewComponent} from './mortality/mortality-standard-view/mortality-standard-view.component';
import {MortalityListComponent} from "./mortality/mortality-list/mortality-list.component";
import {MortalityChartComponent} from "./mortality/mortality-chart/mortality-chart.component";
import {FeedStandardListComponent} from "./feed/feed-standard/feed-standard-list.component";
import {FeedStandardViewComponent} from "./feed/feed-standard-view/feed-standard-view.component";
import {FeedListComponent} from "./feed/feed-list/feed-list.component";
import {FeedTypeListComponent} from "./feed/feed-type/feed-type-list/feed-type-list.component";
import {FeedChartComponent} from "./feed/feed-chart/feed-chart.component";
import {BodyWeightListComponent} from "./body-weight/body-weight-list/body-weight-list.component";
import {BodyWeightStandardListComponent} from "./body-weight/body-weight-standard/body-weight-standard-list.component";
import {
  BodyWeightStandardViewComponent
} from "./body-weight/body-weight-standard-view/body-weight-standard-view.component";
import {BodyWeightChartComponent} from "./body-weight/body-weight-chart/body-weight-chart.component";
import {TemperatureListComponent} from "./temperature/temperature-list/temperature-list.component";
import {TemperatureStandardListComponent} from "./temperature/temperature-standard/temperature-standard-list.component";
import {
  TemperatureStandardViewComponent
} from "./temperature/temperature-standard-view/temperature-standard-view.component";
import {TemperatureChartComponent} from "./temperature/temperature-chart/temperature-chart.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginPage},
  {path: 'farms', component: FarmListComponent},
  {path: 'farm/view/:id', component: FarmViewComponent},
  {path: 'farm/sites', component: SiteListComponent},
  {path: 'farm/houses', component: HouseListComponent},
  {path: 'farm/employees', component: EmployeeListComponent},
  {path: 'flocks', component: FlockListComponent},
  {path: 'flock/view/:id', component: FlockViewComponent},
  {path: 'flock/view/:id#allData', component: FlockDataComponent},
  {path: 'standards', component: StandardListComponent},
  {path: 'standard/view/:id', component: StandardViewComponent},
  {path: 'egg-collection', component: EggCollectionListComponent},
  {path: 'mortality', component: MortalityListComponent},
  {path: 'mortality/chart', component: MortalityChartComponent},
  {path: 'mortality/standard', component: MortalityStandardListComponent},
  {path: 'mortality/standard/view/:id', component: MortalityStandardViewComponent},
  {path: 'feed', component: FeedListComponent},
  {path: 'feed/type', component: FeedTypeListComponent},
  {path: 'feed/standard', component: FeedStandardListComponent},
  {path: 'feed/standard/view/:id', component: FeedStandardViewComponent},
  {path: 'feed/chart', component: FeedChartComponent},
  {path: 'body-weight', component: BodyWeightListComponent},
  {path: 'body-weight/standard', component: BodyWeightStandardListComponent},
  {path: 'body-weight/standard/view/:id', component: BodyWeightStandardViewComponent},
  {path: 'body-weight/chart', component: BodyWeightChartComponent},
  {path: 'temperature', component: TemperatureListComponent},
  {path: 'temperature/standard', component: TemperatureStandardListComponent},
  {path: 'temperature/standard/view/:id', component: TemperatureStandardViewComponent},
  {path: 'temperature/chart', component: TemperatureChartComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
