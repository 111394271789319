<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Feed</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row align-middle">
            <div class="form-group  mx-sm-3 mb-2">
              <label for="fromDate">From</label>
              <div class="input-group">
                <input id="fromDate" name="fromDate" class="form-control" placeholder="dd-mm-yyyy"
                       [(ngModel)]="fromDate" #fromDateDp="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="fromDateDp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="toDate">To</label>
              <div class="input-group">
                <input id="toDate" name="toDate" class="form-control" placeholder="dd-mm-yyyy"
                       [(ngModel)]="toDate" #toDateDp="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="toDateDp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mx-sm-3 mb-2">
              <label for="house">House</label>
              <select class="form-control" id="house" name="site"
                      [(ngModel)]="houseId">
                <option value="">All</option>
                <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-sm btn-primary mx-sm-3" (click)="search()"
                      ngbAutofocus>
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="add()">
                Add
              </button>
            </div>
          </div>
          <table class="table table-hover table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Flock Day</th>
              <th>House</th>
              <th>Date</th>
              <th>Completed By</th>
              <th>Min Standard</th>
              <th>Min Actual</th>
              <th>Max Standard</th>
              <th>Max Actual</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody *ngIf="temperatures">
            <tr *ngFor="let temperature of temperatures">
              <td>{{temperature.flockDay}}</td>
              <td>{{temperature.house}}</td>
              <td>{{temperature.date | date : 'dd MMM yyyy'}}</td>
              <td>{{temperature.completedBy}}</td>
              <td class="text-right">{{temperature.standardMin}}</td>
              <td class="text-right">{{temperature.min | number}}</td>
              <td class="text-right">{{temperature.standardMax}}</td>
              <td class="text-right">{{temperature.max | number}}</td>
              <td>
                <button type="button" class="btn btn-link" title="Edit" *ngIf="authService.hasAuthority('Data Capture')"
                        (click)="edit(temperature)">
                  <span class="fas fa-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-link" title="Edit"
                        *ngIf="authService.hasAuthority('Data Capture')"
                        (click)="delete(temperature)">
                  <span class="fas fa-minus-circle" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>