import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {Mortality} from "../mortality";

@Component({
  selector: 'app-mortality-form',
  templateUrl: './mortality-form.component.html'
})
export class MortalityFormComponent implements OnInit {
  mortality!: Mortality;
  houses!: House[];
  selectedHouse = {} as House;
  mortalityDate: any;
  title!: string;
  defaultMortalityDate = new Date();
  farmId!: string;

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal,
              private authService: AuthService) {
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.defaultMortalityDate.setFullYear(this.defaultMortalityDate.getFullYear());
    if (this.mortality.date) {
      this.mortalityDate = new Date(this.mortality.date);
    } else {
      this.mortalityDate = this.defaultMortalityDate;
    }
    if (this.mortality.id) {
      this.selectedHouse = {id: this.mortality.houseId, name: this.mortality.house} as House;
      this.houses = [this.selectedHouse];
    } else {
      this.mortality.gender = 'Female';
      this.houses = await this.backendService.get('house/withActiveFlock/' + this.farmId);
    }
  }

  save() {
    this.mortality.date = this.mortalityDate.getTime();
    if (this.mortality.id) {
      this.backendService.putSilently('captureMortality', this.mortality).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {

      });
    } else {
      this.backendService.postSilently('captureMortality', this.mortality).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  async getActiveHouses() {
    this.houses = await this.backendService.get(`house/withActiveFlock/${this.mortalityDate.getTime()}/${this.farmId}`);    
  }
}
