import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {version} from '../environments/version';
import {BackendService} from './util/backend.service';
import {UserService} from './util/user.service';
import {AuthService} from './auth/auth.service';
import {Router} from '@angular/router';
import {ModalService} from './util/modal.service';

interface MenuItem {
  text: string;
  link?: string;
  authority?: string;
  show?: boolean;
  items?: SubmenuItem[];
}

interface SubmenuItem {
  text: string;
  link: string;
  authority?: string;
  show?: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  readonly VERSION = version;


  menuItems: MenuItem[] = [
    {text: 'Home', link: ''},
    {text: 'Farms', link: 'farms', authority: 'Administrator'},
    {text: 'Flocks', link: 'flocks', authority: 'Administrator'},
    {
      text: 'Feed', link: 'feed', authority: '',
      items: [
        {text: 'Standards', link: 'feed/standard', authority: 'Administrator'},
        {text: 'Feed Types', link: 'feed/type', authority: 'Administrator'},
        {text: 'Feed', link: 'feed', authority: 'Data Capture'},
        {text: 'Feed Chart', link: 'feed/chart', authority: 'Data Capture'}
      ]
    },
    {
      text: 'Body Weight', link: 'body-weight', authority: '',
      items: [
        {text: 'Standards', link: 'body-weight/standard', authority: 'Administrator'},
        {text: 'Body Weight', link: 'body-weight', authority: 'Data Capture'},
        {text: 'Chart', link: 'body-weight/chart', authority: 'Data Capture'}
      ]
    },
    {
      text: 'Temperature', link: 'temperature', authority: '',
      items: [
        {text: 'Standards', link: 'temperature/standard', authority: 'Administrator'},
        {text: 'Temperature', link: 'temperature', authority: 'Data Capture'},
        {text: 'Chart', link: 'temperature/chart', authority: 'Data Capture'}
      ]
    },
    {
      text: 'Mortality', link: 'mortality', authority: '',
      items: [
        {text: 'Standards', link: 'mortality/standard', authority: 'Administrator'},
        {text: 'Mortality', link: 'mortality', authority: 'Data Capture'},
        {text: 'Chart', link: 'mortality/chart', authority: 'Data Capture'}
      ]
    },
    {
      text: 'Egg Collections', authority: 'Data Capture',
      items: [
        {text: 'Standards', link: 'standards', authority: 'Administrator'},
        {text: 'Egg Collections', link: 'egg-collection', authority: 'Data Capture'}
      ]
    }
  ];
  loggedOnUser: any;

  constructor(
    private backend: BackendService,
    private http: HttpClient,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router,
    modalConfig: NgbModalConfig
  ) {
    modalConfig.backdrop = 'static'; // don't dismiss on backdrop click
    modalConfig.keyboard = false; // don't dismiss with Esc key
    this.filterMenuItems();
  }

  get busy() {
    return this.backend.busy;
  }

  async ngOnInit() {
    this.authService.authSubject.subscribe(state => {
      this.filterMenuItems();
      if (state) {
        this.loggedOnUser = this.authService.loggedOnUser;
        this.router.navigate(['']);
      } else {
        this.loggedOnUser = null;
        this.router.navigate(['login']);
      }
    });
    this.authService.checkIfLoggedOn();
    this.filterMenuItems();
  }

  async logout() {
    this.authService.logout();
  }

  async resetPin() {
    this.modalService.info('Feature not yet implemented.');
  }

  async editProfile() {
    this.modalService.info('Feature not yet implemented.');
  }

  filterMenuItems() {
    // Filter submenus
    for (const menuItem of this.menuItems) {
      if (!menuItem.items) {
        continue;
      }

      for (const submenuItem of menuItem.items) {
        submenuItem.show =
          !submenuItem.authority ||
          this.authService.hasAuthority(submenuItem.authority);
      }

      menuItem.show = menuItem.items.some((sub) => sub.show);
    }

    // Filter top-level menu
    for (const menuItem of this.menuItems.filter((mi) => !mi.items)) {
      menuItem.show =
        !menuItem.authority ||
        this.authService.hasAuthority(menuItem.authority);
    }
  }
}
