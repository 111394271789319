<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light" *ngIf="farm">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h3 class="card-title text-centre">{{farm.name}} Details</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dl class="row">
            <dt class="col-md-2">Name</dt>
            <dd class="col-md-4 colomnleftTextAlign">
              {{farm.name}}
            </dd>
            <dt class="col-md-2">Status</dt>
            <dd class="col-md-4 colomnleftTextAlign">
              {{farm.status}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Manager</dt>
            <dd class="col-md-4 colomnleftTextAlign">
              {{farm.owner}}
            </dd>
            <dt class="col-md-2">Contact Number</dt>
            <dd class="col-md-4 colomnleftTextAlign">
              {{farm.ownerCellnumber}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-md-2">Email Address</dt>
            <dd class="col-md-4 colomnleftTextAlign">
              {{farm.ownerEmail}}
            </dd>
          </dl>
          <br/>
          <div class="col-md-12 binPanelsBorder">

            <nav ngbNav #nav="ngbNav" class="nav-tabs mt-3">
              <ng-container ngbNavItem>
                <a ngbNavLink>Sites</a>
                <ng-template ngbNavContent>
                  <app-site-list [farmId]="farm.id"></app-site-list>
                </ng-template>
              </ng-container>

              <ng-container ngbNavItem>
                <a ngbNavLink>Houses</a>
                <ng-template ngbNavContent>
                  <app-house-list [farmId]="farm.id"></app-house-list>
                </ng-template>
              </ng-container>

              <ng-container ngbNavItem>
                <a ngbNavLink>Employees</a>
                <ng-template ngbNavContent>
                  <app-employee-list [farmId]="farm.id"></app-employee-list>
                </ng-template>
              </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>