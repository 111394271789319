import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './message-modal.component.html',
  styleUrls:  ['./message-modal.component.css']
})
export class MessageModalComponent implements OnInit {
  successMessage?: string;
  infoMessage?: string;
  errorMessages?: string[];
  error?: Error;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.error) {
      this.errorMessages = this.errorMessagesFor(this.error);
    }
  }

  errorMessagesFor(e: Error): string[] {
    console.error('Error', e);

    if (e instanceof HttpErrorResponse) {
      if (e.status === 404) {
        return ['404 - Not Found (' + e.url + ')'];
      }
      if (typeof e.error === 'string') {
        return [e.error];
      }
      if (e.error.message) {
        return [e.error.message];
      }
      if (e.error.Message) {
        return [e.error.Message];
      }
      if (e.error.errors) {
        return e.error.errors.map((err: { message: string }) => err.message);
      }
      return ['There was a problem processing your request.'];
    }

    return (
      (e && [e.message]) || ['There was a problem processing your request.']
    );
  }
}
