import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {ActivatedRoute} from '@angular/router';
import {Business} from '../business';

@Component({
  selector: 'app-farm-view',
  templateUrl: './farm-view.component.html',
  styleUrls: ['./farm-view.component.css']
})
export class FarmViewComponent implements OnInit {
  private farmId!: string;
  farm!: Business;

  constructor(private backendService: BackendService,
              private route: ActivatedRoute) { }

  async ngOnInit() {
    this.farmId = this.route.snapshot.params.id;
    this.farm = await this.backendService.get<Business>('business/' + this.farmId);
  }

}
