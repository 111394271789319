import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {Feed} from "../feed";
import {FeedType} from "../feed-type/feed-type";

@Component({
  selector: 'app-feed-form',
  templateUrl: './feed-form.component.html'
})
export class FeedFormComponent implements OnInit {
  feed!: Feed;
  houses!: House[];
  selectedHouse = {} as House;
  feedDate: any;
  title!: string;
  defaultFeedDate = new Date();
  farmId!: string;
  feedTypes!: FeedType[];

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal,
              private authService: AuthService) {
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.defaultFeedDate.setFullYear(this.defaultFeedDate.getFullYear());
    if (this.feed.date) {
      this.feedDate = new Date(this.feed.date);
    } else {
      this.feedDate = this.defaultFeedDate;
    }
    if (this.feed.id) {
      this.selectedHouse = {id: this.feed.houseId, name: this.feed.house} as House;
      this.houses = [this.selectedHouse];
    } else {
      this.feed.gender = 'Female';
      this.houses = await this.backendService.get('house/withActiveFlock/' + this.farmId);
    }
    this.feedTypes = await this.backendService.get('feedType');
  }

  save() {
    this.feed.date = this.feedDate.getTime();
    if (this.feed.id) {
      this.backendService.putSilently('captureFeed', this.feed).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {

      });
    } else {
      this.backendService.postSilently('captureFeed', this.feed).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  async getActiveHouses() {
    this.houses = await this.backendService.get(`house/withActiveFlock/${this.feedDate.getTime()}/${this.farmId}`);    
  }
}
