import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Directive({
  selector: '[appMin]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true },
  ],
})
export class MinValidatorDirective implements Validator {
  @Input('appMin') min: number | null = null;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.min !== null ? Validators.min(this.min)(control) : null;
  }
}
