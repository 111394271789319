<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #mortalityForm="ngForm" (ngSubmit)="save()">
    <div class="form-row row">
      <div class="form-group col">
        <label for="house">House</label>
        <app-drop-down id="house" name="house" required stretch="true"  [disabled]="mortality.id"
                [(ngModel)]="mortality.houseId">
          <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="gender">Gender</label>
        <app-drop-down id="gender" name="gender" required stretch="true" [disabled]="mortality.id"
                [(ngModel)]="mortality.gender">
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </app-drop-down>
      </div>
      <div class="form-group col">
        <label for="mortalityDate">Date</label>
        <div class="input-group">
          <input id="mortalityDate" name="mortalityDate" class="form-control" placeholder="dd-mm-yyyy"
                 (ngModelChange)="getActiveHouses()"
                 [(ngModel)]="mortalityDate" #mortalityDatePicker="ngbDatepicker" [readOnly]="mortality.id"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt" [disabled]="mortality.id"
                    (click)="mortalityDatePicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row row">

      <div class="form-group col-6">
        <label for="transferIn">Transfer In</label>
        <app-text-input type="number" id="transferIn"
                        name="transferIn" [(ngModel)]="mortality.transferIn" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="transferOut">Transfer Out</label>
        <app-text-input type="number" name="transferOut" id="transferOut" [(ngModel)]="mortality.transferOut"
                        stretch="true" required></app-text-input>
      </div>
        
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="heatStress">Heat Stress</label>
        <app-text-input type="number" name="heatStress" id="heatStress" [(ngModel)]="mortality.heatStress" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="legProblems">Leg Problems</label>
        <app-text-input type="number" name="legProblems" id="legProblems" [(ngModel)]="mortality.legProblems" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="backScratches">Back Stretches</label>
        <app-text-input type="number" name="backScratches" id="backScratches"
                        [(ngModel)]="mortality.backScratches" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="cannibalism">Cannibalism</label>
        <app-text-input type="number" name="cannibalism" id="cannibalism" [(ngModel)]="mortality.cannibalism"
                        appMin="0"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="starveOut">Starve Out</label>
        <app-text-input type="number" name="starveOut" id="starveOut" [(ngModel)]="mortality.starveOut"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="prolapse">Prolapse</label>
        <app-text-input type="number" name="small" id="prolapse" [(ngModel)]="mortality.prolapse"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="peritonitis">Peritonitis</label>
        <app-text-input type="number" name="peritonitis" id="peritonitis" [(ngModel)]="mortality.peritonitis"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="injury">Injury</label>
        <app-text-input type="number" name="injury" id="injury" [(ngModel)]="mortality.injury"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="nonStarter">Non Starter</label>
        <app-text-input type="number" name="nonStarter" id="nonStarter" [(ngModel)]="mortality.nonStarter"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="yolkSacInfection">Yolk Sac Infection</label>
        <app-text-input type="number" name="yolkSacInfection" id="yolkSacInfection" [(ngModel)]="mortality.yolkSacInfection"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="sexingError">Sexing Error</label>
        <app-text-input type="number" name="sexingError" id="sexingError" [(ngModel)]="mortality.sexingError"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="cullSale">Cull Sale</label>
        <app-text-input type="number" name="cullSale" id="cullSale" [(ngModel)]="mortality.cullSale"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="depleted">Depleted</label>
        <app-text-input type="number" name="depleted" id="depleted" [(ngModel)]="mortality.depleted"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="decomposed">Decomposed</label>
        <app-text-input type="number" id="decomposed"
                        name="decomposed" [(ngModel)]="mortality.decomposed" appMin="0"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col-6">
        <label for="navelInfection">Navel Infection</label>
        <app-text-input type="number" name="navelInfection" id="navelInfection" [(ngModel)]="mortality.navelInfection"
                        stretch="true" required></app-text-input>
      </div>
      <div class="form-group col-6">
        <label for="injury">Vaccine Infection</label>
        <app-text-input type="number" name="vaccineInfection" id="vaccineInfection" [(ngModel)]="mortality.vaccineInfection"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!mortalityForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>