import {Component, OnInit} from "@angular/core";
import {BackendService} from "../../util/backend.service";
import {StandardImport} from "../../standard/standard-import";
import bsCustomFileInput from "bs-custom-file-input";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-temperature-standard-view',
  templateUrl: './temperature-standard-list.component.html'
})
export class TemperatureStandardListComponent implements OnInit {

  private importFile!: File;
  standardImport: StandardImport = { name: '', metric: 'Temperature'} as StandardImport;
  standards = [] as StandardImport[];
  templateUrl = environment.apiUrl + 'templates/temperatureStandardTemplate.csv';

  constructor(private backendService: BackendService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    bsCustomFileInput.init();
    this.standardImport.farmId = this.authService.getFarmId();
    this.loadStandards();
  }

  private loadStandards() {
    this.backendService.get<StandardImport[]>('standard/all/Temperature').then((res: StandardImport[]) => {
      this.standards = res;
    });
  }

  uploadFiles(fileList: FileList) {
    console.log('file', fileList);
    this.importFile = fileList[0];
  }

  import() {
    console.log(this.standardImport.name);
    if (this.importFile) {
      const fileReader: FileReader = new FileReader();
      const self = this;
      fileReader.onloadend = (x: any) => {
        self.standardImport.importedFile = fileReader.result;
        self.backendService.postSilently('standard/import', self.standardImport)
          .then((res: any) => {
            self.loadStandards();
          });
      }
      fileReader.readAsText(this.importFile);
    }
  }

}