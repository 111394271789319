import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BackendService} from '../../util/backend.service';
import {ModalService} from '../../util/modal.service';
import {Business} from '../../farm/business';
import {Flock} from '../flock';
import {FlockFormComponent} from '../flock-form/flock-form.component';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-flock-list',
  templateUrl: './flock-list.component.html',
  styleUrls: ['./flock-list.component.css']
})
export class FlockListComponent implements OnInit {

  flocks = [] as Flock[];

  constructor(private backendService: BackendService,
              private router: Router,
              private modalService: ModalService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.loadFarms();
  }

  private loadFarms() {
    this.backendService.get<Flock[]>('flock').then((res: Flock[]) => {
      this.flocks = res;
    });
  }

  async edit(flock: Flock) {

    const modalRef = this.modalService.openRef(FlockFormComponent);

    const component = modalRef.componentInstance as FlockFormComponent;
    component.flock = flock;
    component.title = 'Edit ' + flock.code;
    component.farmId = flock.farmId;
    await modalRef.result;
    this.loadFarms();
  }

  async add() {

    const modalRef = this.modalService.openRef(FlockFormComponent);

    const component = modalRef.componentInstance as FlockFormComponent;
    component.flock = { } as Flock;
    component.title = 'Add a Flock ';
    component.farmId = this.authService.getFarmId();
    await modalRef.result;
    this.loadFarms();
  }

  details(flock: Flock) {
    this.router.navigate(['flock', 'view', flock.id]);
  }
}
