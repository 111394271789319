import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BackendService} from '../../util/backend.service';
import {Flock} from '../flock';

@Component({
  selector: 'app-flock-view',
  templateUrl: './flock-view.component.html',
  styleUrls: ['./flock-view.component.css']
})
export class FlockViewComponent implements OnInit {


  flock!: Flock;
  constructor(private backendService: BackendService,
              public route: ActivatedRoute) { }

  async ngOnInit() {
    const flockId = this.route.snapshot.params.id;
    this.flock = await this.backendService.get('flock/' + flockId);
  }

}
