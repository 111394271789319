import { Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SimpleValueAccessor } from './simple-value-accessor';

@Component({
  selector: 'app-password-input',
  template: `
    <!--suppress TypeScriptUnresolvedVariable -->
    <!-- The name attribute is set on the input so that CzFixture.valuesOfInputs() works -->
    <input type="password" class="form-control"
           [ngStyle]="{width: stretch ? '100%' : 'auto'}"
           #input
           [autocomplete]="disableAutoComplete && 'new-password'"
           [attr.name]="name"
           (input)="onChange($event.target.value)"
           (blur)="onTouched()"
           [disabled]="disabled">
    <app-control-errors [ngStyle]="{marginLeft: stretch ? '0' : '10px', marginTop: stretch ? '10px' : '0'}"
                        [control]="controlDirective.control"
                        [customErrors]="customErrors">
    </app-control-errors>
  `,
  styles: [':host {display: flex; flex-wrap: wrap}']
})
export class TextPasswordComponent extends SimpleValueAccessor {

  @Input() name?: string;
  @Input() customErrors = {};
  @Input() stretch = false;
  @Input() disableAutoComplete = false;
  @ViewChild('input', { static: false }) input!: ElementRef;

  constructor(@Optional() @Self() controlDirective: NgControl) {
    super(controlDirective);
  }

  writeValue(value: any) {
    this.input.nativeElement.value = value || '';
  }

}
