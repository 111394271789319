import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {FeedImport} from '../feed-import';
import bsCustomFileInput from 'bs-custom-file-input';
import {Route, Router} from '@angular/router';
import {House} from "../../farm/house/house";
import {Feed} from "../../feed/feed";
import {AuthService} from "../../auth/auth.service";
import {DatePipe, formatDate} from "@angular/common";
import {ModalService} from "../../util/modal.service";
import {FeedFormComponent} from "../feed-form/feed-form.component";

@Component({
  selector: 'app-feed-list',
  templateUrl: './feed-list.component.html',
  styleUrls: ['./feed-list.component.css']
})
export class FeedListComponent implements OnInit {
  houses!: House[];
  feedFromDate = new Date();
  feedToDate = new Date();
  houseId = '';
  feeds!: Feed[];
  private farmId!: string;

  constructor(public authService: AuthService,
              private backendService: BackendService,
              private datePipe: DatePipe,
              private modalService: ModalService) {
  }

  ionViewWillEnter() {
    this.ngOnInit();
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.houses = await this.backendService.get('house/' + this.farmId);
    await this.search();
  }

  async search() {
    const fromDate = this.feedFromDate.getTime();
    const toDate = this.feedToDate.getTime();
    this.feeds = await this.backendService.postSilently('feed',
      {houseId: this.houseId, fromDate: fromDate, toDate: toDate});
  }

  status(status: string) {
    switch (status) {
      case 'Pending':
        return 'text-warning';
      case 'Confirmed':
        return 'text-success';
      case 'Exception':
        return 'text-danger';
    }
    return 'text-info';
  }

  async add() {
    const modalRef = this.modalService.openRef(FeedFormComponent, 'lg');
    const component = modalRef.componentInstance as FeedFormComponent;
    component.title = 'Add feed ';
    component.feed = {} as Feed;
    await modalRef.result;
    this.search();
  }

  async edit(feed: Feed) {
    const modalRef = this.modalService.openRef(FeedFormComponent, 'lg');
    const component = modalRef.componentInstance as FeedFormComponent;
    component.title = 'Edit Feed ';
    component.feed = feed;
    await modalRef.result;
    this.search();
  }

  async delete(collection: Feed) {
    this.modalService.confirm('Deleting feed (' + collection.house + formatDate(this.feedFromDate, ' - dd MMMM yyyy', 'en')
      + ') will erase it from the system. Are you sure you want to delete this feed?' )
      .then( response => {
          if (response){
            this.backendService.delete('feed/' + collection.id).then( response => {
              this.modalService.info('feed successfully deleted');
              this.search();
            });
          }
        }, errObj => {
          this.modalService.error('Error occured while deleting the collection.');
        }
      );
  }

  standardStatus(standard: number, actual: number) {
    if(standard !== actual) {
      return 'text-right text-danger font-weight-bold';
    } else {
      return 'text-right text-success font-weight-bold';
    }
  }
}
