import { Injectable, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MessageModalComponent } from './message-modal/message-modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private ngbModal: NgbModal) {}

  openComponent<T>(modalComponent: Type<T>): T {
    const modalRef = this.ngbModal.open(modalComponent);
    return modalRef.componentInstance as T;
  }

  openRef(modalComponent: any, sizeType?: string) {
    if (sizeType && sizeType.length > 0) {
      return this.ngbModal.open(modalComponent, { size: sizeType });
    }
    return this.ngbModal.open(modalComponent);
  }

  async confirm(message: string): Promise<boolean> {
    const modalRef = this.openRef(ConfirmModalComponent);
    const confirmModal = modalRef.componentInstance as ConfirmModalComponent;
    confirmModal.message = message;
    return await modalRef.result;
  }

  error(message: string) {
    const messageModal = this.openComponent(MessageModalComponent);
    messageModal.error = new Error(message);
  }

  info(message: string) {
    const messageModal = this.openComponent(MessageModalComponent);
    messageModal.infoMessage = message;
  }
}
