import {Component} from '@angular/core';
import {BackendService} from '../util/backend.service';
import {TodayCollection} from './todayCollection';

@Component({
  templateUrl: './home.component.html',
})
export class HomeComponent {
  todayCollections!: TodayCollection [];
  constructor(private backendService: BackendService) {
    this.load();
  }

  async load() {
    this.todayCollections = await this.backendService.get('todayCollections');
  }
}
