import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-control-errors',
  template: `
    <div *ngIf="control && !control.valid" style="color:red">
      <div *ngFor="let error of allErrors()" translate>
        {{ errorMessageFor(error) }}
      </div>
    </div>
  `,
})
export class ControlErrorsComponent implements OnInit {
  @Input() control!: AbstractControl | null;
  @Input() customErrors = {};

  errorMessages: { [key: string]: string } = {
    required: 'This field is required',
    email: 'Invalid email address',
    pattern: 'Value does not match format',
    minlength: 'Value is too short',
    maxlength: 'Value is too long',
    min: 'Value is too small',
    max: 'Value is too large',
    phoneNumber:
      'Invalid phone number, make sure the country code is included without the +',
    money: 'Must be a monetary value',
    moneyWithoutCents: 'Must be a monetary value without cents',
    unique: 'Value is a duplicate',
  };

  ngOnInit() {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.customErrors,
    };
  }

  allErrors() {
    return (
      this.control && this.control.errors && Object.keys(this.control.errors)
    );
  }

  errorMessageFor(error: string) {
    return this.errorMessages[error] || `Error message not set for: ${error}`;
  }
}
