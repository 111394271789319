<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Code</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="code" [(ngModel)]="house.code" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Name</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="name" [(ngModel)]="house.name" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Site</label>
      </div>
      <div class="form-group col-sm-6">
        <select class="form-control" id="site" name="site"
                [(ngModel)]="house.siteId">
          <option *ngFor="let site of sites" [value]="site.id">{{site.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Floor Area Square (m2)</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input type="number" name="flourArea" [(ngModel)]="house.floorAreaSquare"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Female Feeder Length (m)</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input type="number" name="feederLength" [(ngModel)]="house.femaleFeederLength"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Number of Drinker Spaces</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input type="number" name="drinkerSpaces" [(ngModel)]="house.numberOfDrinkerSpaces"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Number of Nest Box Holes</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input type="number" name="nestBoxHoles" [(ngModel)]="house.numberOfNestBoxHoles"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>