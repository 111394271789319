<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Code</label>
      </div>
      <div class="form-group col-sm-4">
        <app-text-input name="code" [(ngModel)]="site.code" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Name</label>
      </div>
      <div class="form-group col-sm-8">
        <app-text-input name="name" [(ngModel)]="site.name" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>