import {Component, OnInit} from '@angular/core';
import {Flock} from '../flock';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../util/backend.service';
import {House} from '../../farm/house/house';
import {StandardImport} from '../../standard/standard-import';

@Component({
  selector: 'app-flock-form',
  templateUrl: './flock-form.component.html',
  styleUrls: ['./flock-form.component.css']
})
export class FlockFormComponent implements OnInit {

  title = 'Add Flock';
  flock!: Flock;
  flockHatchDate = new Date();
  flockDateMoved = new Date();
  houses!: House[];
  standards!: StandardImport[];
  farmId!: string;

  constructor(
    private backendService: BackendService,
    public activeModal: NgbActiveModal) { }

  async ngOnInit() {
    if (this.flock.id){
      this.flockDateMoved = new Date(this.flock.dateMoved);
      this.flockHatchDate = new Date(this.flock.hatchDate);
    }
    this.houses = await this.backendService.get('house/' + this.farmId);
    this.standards = await this.backendService.get('standard/findActive');
  }

  save() {
    this.flock.hatchDate = this.flockHatchDate.getTime();
    this.flock.dateMoved = this.flockDateMoved.getTime();
    if (this.flock.id) {
      this.backendService.putSilently('flock', this.flock).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {

      });
    } else {
      this.flock.farmId = this.farmId;
      this.backendService.postSilently('flock', this.flock).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }
}
