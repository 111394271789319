import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ChartsModule} from 'ng2-charts';
import {
  NgbAccordionModule,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {ConfirmModalComponent} from './util/confirm-modal/confirm-modal.component';
import {AutoCompleteComponent} from './util/form/auto-complete-drop-down/auto-complete.component';
import {ControlErrorsComponent} from './util/form/control-errors.component';
import {DropDownComponent} from './util/form/drop-down.component';
import {TextInputSearchComponent} from './util/form/text-input-search/text-input-search.component';
import {TextInputComponent} from './util/form/text-input.component';
import {MaxValidatorDirective} from './util/form/validation/max-validator.directive';
import {MinValidatorDirective} from './util/form/validation/min-validator.directive';
import {MoneyWithoutCentsValidatorDirective} from './util/form/validation/money-no-cents-validator.directive';
import {MoneyValidatorDirective} from './util/form/validation/money-validator.directive';
import {PhoneNumberValidatorDirective} from './util/form/validation/phone-number-validator.directive';
import {UniqueValidatorDirective} from './util/form/validation/unique-validator.directive';
import {MessageModalComponent} from './util/message-modal/message-modal.component';
import {PaginationComponent} from './util/pagination/pagination.component';
import {LoginPage} from './auth/login/login.page';
import {TextPasswordComponent} from './util/form/text-password.component';
import {FarmListComponent} from './farm/farm-list/farm-list.component';
import {FarmFormComponent} from './farm/farm-form/farm-form.component';
import {FarmViewComponent} from './farm/farm-view/farm-view.component';
import {SiteListComponent} from './farm/site/site-list/site-list.component';
import {SiteFormComponent} from './farm/site/site-form/site-form.component';
import {HouseFormComponent} from './farm/house/house-form/house-form.component';
import {HouseListComponent} from './farm/house/house-list/house-list.component';
import {EmployeeListComponent} from './farm/employee/employee-list/employee-list.component';
import {EmployeeFormComponent} from './farm/employee/employee-form/employee-form.component';
import {SADateParserFormatter} from './util/sa-date-parser-formatter';
import {FlockListComponent} from './flock/flock-list/flock-list.component';
import {FlockFormComponent} from './flock/flock-form/flock-form.component';
import {FlockViewComponent} from './flock/flock-view/flock-view.component';
import {FlockDataComponent} from './flock/flock-data/flock-data.component';
import {StandardListComponent} from './standard/standard-list/standard-list.component';
import {StandardViewComponent} from './standard/standard-view/standard-view.component';
import {EggCollectionListComponent} from './egg-collection/egg-collection-list/egg-collection-list.component';
import {EggCollectionFormComponent} from './egg-collection/egg-collection-form/egg-collection-form.component';
import {DatePipe} from '@angular/common';
import {MortalityStandardListComponent} from "./mortality/mortality-standard/mortality-standard-list.component";
import {MortalityStandardViewComponent} from "./mortality/mortality-standard-view/mortality-standard-view.component";
import {MortalityListComponent} from "./mortality/mortality-list/mortality-list.component";
import {MortalityFormComponent} from "./mortality/mortality-form/mortality-form.component";
import {MortalityChartComponent} from "./mortality/mortality-chart/mortality-chart.component";
import {FeedStandardListComponent} from "./feed/feed-standard/feed-standard-list.component";
import {FeedStandardViewComponent} from "./feed/feed-standard-view/feed-standard-view.component";
import {FeedListComponent} from "./feed/feed-list/feed-list.component";
import {FeedFormComponent} from "./feed/feed-form/feed-form.component";
import {FeedTypeFormComponent} from "./feed/feed-type/feed-type-form/feed-type-form.component";
import {FeedTypeListComponent} from "./feed/feed-type/feed-type-list/feed-type-list.component";
import {FlockStandardFormComponent} from "./flock/flock-standard-form/flock-standard-form.component";
import {FeedChartComponent} from "./feed/feed-chart/feed-chart.component";
import {BodyWeightChartComponent} from "./body-weight/body-weight-chart/body-weight-chart.component";
import {BodyWeightStandardListComponent} from "./body-weight/body-weight-standard/body-weight-standard-list.component";
import {
  BodyWeightStandardViewComponent
} from "./body-weight/body-weight-standard-view/body-weight-standard-view.component";
import {BodyWeightListComponent} from "./body-weight/body-weight-list/body-weight-list.component";
import {BodyWeightFormComponent} from "./body-weight/body-weight-form/body-weight-form.component";
import {TemperatureStandardListComponent} from "./temperature/temperature-standard/temperature-standard-list.component";
import {
  TemperatureStandardViewComponent
} from "./temperature/temperature-standard-view/temperature-standard-view.component";
import {TemperatureListComponent} from "./temperature/temperature-list/temperature-list.component";
import {TemperatureFormComponent} from "./temperature/temperature-form/temperature-form.component";
import {TemperatureChartComponent} from "./temperature/temperature-chart/temperature-chart.component";

@NgModule({
  declarations: [
    AppComponent,
    PaginationComponent,
    MinValidatorDirective,
    MaxValidatorDirective,
    MoneyValidatorDirective,
    MoneyWithoutCentsValidatorDirective,
    PhoneNumberValidatorDirective,
    UniqueValidatorDirective,
    ControlErrorsComponent,
    TextPasswordComponent,
    TextInputComponent,
    DropDownComponent,
    MessageModalComponent,
    ConfirmModalComponent,
    HomeComponent,
    LoginPage,
    AutoCompleteComponent,
    TextInputSearchComponent,
    FarmListComponent,
    FarmFormComponent,
    FarmViewComponent,
    SiteListComponent,
    SiteFormComponent,
    HouseFormComponent,
    HouseListComponent,
    EmployeeListComponent,
    EmployeeFormComponent,
    FlockListComponent,
    FlockFormComponent,
    FlockViewComponent,
    FlockDataComponent,
    StandardListComponent,
    StandardViewComponent,
    EggCollectionListComponent,
    EggCollectionFormComponent,
    MortalityStandardViewComponent,
    MortalityStandardListComponent,
    FlockStandardFormComponent,
    MortalityListComponent,
    MortalityFormComponent,
    MortalityChartComponent,
    FeedStandardListComponent,
    FeedStandardViewComponent,
    FeedListComponent,
    FeedFormComponent,
    FeedTypeFormComponent,
    FeedTypeListComponent,
    FeedChartComponent,
    BodyWeightStandardListComponent,
    BodyWeightStandardViewComponent,
    BodyWeightListComponent,
    BodyWeightFormComponent,
    BodyWeightChartComponent,
    TemperatureStandardListComponent,
    TemperatureStandardViewComponent,
    TemperatureListComponent,
    TemperatureFormComponent,
    TemperatureChartComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbProgressbarModule,
    NgbTimepickerModule,
    ChartsModule
  ],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: SADateParserFormatter},
    DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {
}
