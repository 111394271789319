export class EggCollection {
  id: string;
  houseId: string;
  house: string;
  number: number;
  dateTimeInserted: number;
  collectedDateTime: number;
  completedBy: string;
  checkedBy: string;
  nestBox: number = 0;
  jumbo: number = 0;
  mShape: number = 0;
  dirties: number = 0;
  floor: number = 0;
  softShell: number = 0;
  small: number = 0;
  cracks: number = 0;
  broken: number = 0;
  totalRejected: number;
  totalEggs: number;
  checkedTotal: number;
  status: string;
}
