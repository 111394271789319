import {Component, Input, OnInit} from '@angular/core';
import {HouseFormComponent} from '../../house/house-form/house-form.component';
import {House} from '../../house/house';
import {BackendService} from '../../../util/backend.service';
import {ModalService} from '../../../util/modal.service';
import {Employee} from '../employee';
import {EmployeeFormComponent} from '../employee-form/employee-form.component';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  @Input() farmId!: string;
  employees!: Employee[];
  constructor(private backendService: BackendService,
              private modalService: ModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.employees = await this.backendService.get('employee/GetFarmEmployee/' + this.farmId);
  }

  async edit(employee: Employee){
    const modalRef = this.modalService.openRef(EmployeeFormComponent);
    const component = modalRef.componentInstance as EmployeeFormComponent;
    component.employee = employee;
    component.title = 'Edit ' + employee.name;
    await modalRef.result;
    this.load();
  }

  async add(){
    const modalRef = this.modalService.openRef(EmployeeFormComponent);
    const component = modalRef.componentInstance as EmployeeFormComponent;
    component.title = 'Add Employee ';
    component.employee = { employerId: this.farmId} as Employee;
    await modalRef.result;
    this.load();
  }


}
