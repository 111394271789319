import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {Temperature} from "../temperature";

@Component({
  selector: 'app-temperature-form',
  templateUrl: './temperature-form.component.html'
})
export class TemperatureFormComponent implements OnInit {
  temperature!: Temperature;
  houses!: House[];
  selectedHouse = {} as House;
  temperatureDate: any;
  title!: string;
  defaulttemperatureDate = new Date();
  farmId!: string;

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal,
              private authService: AuthService) {
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.defaulttemperatureDate.setFullYear(this.defaulttemperatureDate.getFullYear());
    if (this.temperature.date) {
      this.temperatureDate = new Date(this.temperature.date);
    } else {
      this.temperatureDate = this.defaulttemperatureDate;
    }
    if (this.temperature.id) {
      this.selectedHouse = {id: this.temperature.houseId, name: this.temperature.house} as House;
      this.houses = [this.selectedHouse];
    } else {
      this.houses = await this.backendService.get('house/withActiveFlock/' + this.farmId);
    }
  }

  save() {
    this.temperature.date = this.temperatureDate.getTime();
    if (this.temperature.id) {
      this.backendService.putSilently('captureTemperature', this.temperature).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {

      });
    } else {
      this.backendService.postSilently('captureTemperature', this.temperature).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  async getActiveHouses() {
    this.houses = await this.backendService.get(`house/withActiveFlock/${this.temperatureDate.getTime()}/${this.farmId}`);    
  }
}
