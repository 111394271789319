<header *ngIf="loggedOnUser">
      <span class="navbar-brand" style="color: white">
        <h1 class="logo">
      </h1>
      </span>
  <nav class="navbar navbar-expand-lg" style="padding-left: 11em">
    <div class="container-fluid">

      <ul class="navbar-nav" style="width: 100%">
        <ng-container *ngFor="let menuItem of menuItems">
          <li *ngIf="menuItem.show && !menuItem.items" class="nav-item">
            <a class="nav-link" [routerLink]="menuItem.link">
              {{ menuItem.text }}
            </a>
          </li>

          <li
            *ngIf="menuItem.show && menuItem.items"
            class="nav-item"
            ngbDropdown
          >
            <a
              class="nav-link"
              style="cursor: pointer"
              ngbDropdownToggle
              role="button"
            >
              {{ menuItem.text }}
            </a>
            <div ngbDropdownMenu class="dropdown-menu">
              <ng-container *ngFor="let submenuItem of menuItem.items">
                <a
                  *ngIf="submenuItem.show"
                  ngbDropdownItem
                  [routerLink]="submenuItem.link"
                >
                  {{ submenuItem.text }}
                </a>
              </ng-container>
            </div>
          </li>
        </ng-container>
      </ul>
      <div class="nav-item dropleft"  #myDrop="ngbDropdown" ngbDropdown>
        <a class="nav-link" id="dropdownMenuLink" ngbDropdownToggle>
          <span *ngIf="!loggedOnUser.photoUrl">
            <span class="fas fa-user-circle" aria-hidden="true" width="30"height="30"></span>
          </span>
          <img *ngIf="loggedOnUser.photoUrl" src="{{ loggedOnUser.photoUrl }}" title="{{loggedOnUser.name}}"
               alt="{{loggedOnUser.name}}"
               width="30" height="30" class="rounded-circle">
        </a>
        <div aria-labelledby="dropdownMenuLink" ngbDropdownMenu>
          <a class="dropdown-item" (click)="editProfile()">{{loggedOnUser.name}}</a>
          <a class="dropdown-item" (click)="resetPin()">Change Password</a>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </div>
    </div>
  </nav>
</header>

<div *ngIf="busy" class="overlay">
  <span>Please Wait</span>
</div>

<div class="container-fluid min-vh-100" style="padding-top: 30px" >
  <router-outlet></router-outlet>
</div>

<footer id="footer">
  <section
    class="footer-top"
    style="text-align: center; background: #9a9a9a; padding: 20px"
  >
    <div class="container-fluid">
      <h4 class="center-title">CONTACT US</h4>

      <div class="footer-text" style="color: white">
        <strong>Parent Stock Management System</strong> | South Africa
      </div>

      <div class="footer-text">
        {{ VERSION.commit.substring(0, 7) }} | {{ VERSION.branch }} |
        {{ VERSION.date }}
      </div>
    </div>
  </section>
</footer>
