import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {FlockData} from '../flock-standard';
import {Flock} from "../flock";
import {ModalService} from "../../util/modal.service";
import {from} from "rxjs";
import * as moment from 'moment';
import {FlockStandardFormComponent} from "../flock-standard-form/flock-standard-form.component";

@Component({
  selector: 'app-flock-data',
  templateUrl: './flock-data.component.html',
  styleUrls: ['./flock-data.component.css']
})
export class FlockDataComponent implements OnInit {

  @Input() flockId!: string;
  flockData!: FlockData[];
  allFlockData!: FlockData[];
  dataToDisplay: string = 'henProduction';
  dateToDisplay = new Date();
  toDateToDisplay = new Date();
  availableDataTypes = [
    {id: 'all', value: 'All'},
    {id: 'eggWeight', value: 'Egg Weight'},
    {id: 'feed', value: 'Feed'},
    {id: 'fertility', value: 'Fertility'},
    {id: 'hatchability', value: 'Hatchability'},
    {id: 'henProduction', value: 'Hen Production'},
    {id: 'mating', value: 'Mating'},
    {id: 'mortality', value: 'Mortality'},
    {id: 'pulletEggs', value: 'Pullet Eggs'},
    {id: 'rejectedEggs', value: 'RejectedEggs'},
    {id: 'weight', value: 'Bird Weight'}
  ];
  flock!: Flock;
  today!: number;

  constructor(private backendService: BackendService,
              private modalService: ModalService) {
    this.availableDataTypes.filter((dt: any) => dt.id !== 'all').forEach((dt: any) => {
      this.availableDataTypes[0].id += dt.id;
    });
    this.today = new Date().getTime();
  }

  async ngOnInit() {
    this.flock = await this.backendService.get('flock/' + this.flockId);
    await this.loadFlockData();
  }

  display(dataType: string) {
    return this.dataToDisplay.indexOf(dataType) > -1;
  }

  toggle(dataType: string) {
    this.dataToDisplay = dataType;
  }

  filter(date: any, toDate: any) {
    console.log(date);
    const fromDate = moment(date).startOf('day');
    const endDate = moment(toDate).endOf('day');
    this.flockData = this.allFlockData.filter(fd => moment(fd.date).isBetween(fromDate, endDate));
  }

  async setStandards() {

    const modalRef = this.modalService.openRef(FlockStandardFormComponent);

    const component = modalRef.componentInstance as FlockStandardFormComponent;
    component.flock = this.flock;
    component.title = 'Edit ' + this.flock.code;
    component.farmId = this.flock.farmId;
    await modalRef.result;
    await this.loadFlockData();
  }

  private async loadFlockData() {
    this.allFlockData = await this.backendService.get('flockData/' + this.flockId);
    this.filter(this.dateToDisplay, this.toDateToDisplay);
  }

  setDateToDisplay(dateToDisplay: number) {
    this.dateToDisplay = new Date(dateToDisplay);
    this.filter(this.dateToDisplay, this.toDateToDisplay);
  }

  setToDateToDisplay(toDate: number) {
    this.toDateToDisplay = new Date(toDate);
    this.filter(this.dateToDisplay, this.toDateToDisplay);
  }
}
