import { ControlValueAccessor, NgControl } from '@angular/forms';

export abstract class SimpleValueAccessor implements ControlValueAccessor {
  onChange!: (value: any) => void;
  onTouched!: () => void;
  disabled = false;

  protected constructor(public controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  abstract writeValue(value: any): void;

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
