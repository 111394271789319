import {Component, OnInit} from '@angular/core';
import {Business} from '../business';
import {BackendService} from '../../util/backend.service';
import {Employee} from '../employee/employee';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-farm-form',
  templateUrl: './farm-form.component.html',
  styleUrls: ['./farm-form.component.css']
})
export class FarmFormComponent implements OnInit {
  title = 'Add Farm';
  farm!: Business;
  employees!: Employee[];
  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal) { }

  async ngOnInit() {
    this.employees = await this.backendService.get<Employee[]>('employee');
  }

  saveFarm() {
    if (this.farm.id) {
      this.backendService.putSilently('business', this.farm).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    } else {
      this.backendService.postSilently('business', this.farm).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }
}
