<section class="container-fluid content-area">
  <div class="row">
    <div class="col-sm-12">
      <div class="card bg-light">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12">
              <h1>Mortality</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row align-middle">
            <div class="form-group  mx-sm-3 mb-2">
              <label for="mortalityFromDate">From</label>
              <div class="input-group">
                <input id="mortalityFromDate" name="mortalityFromDate" class="form-control" placeholder="dd-mm-yyyy"
                       [(ngModel)]="mortalityFromDate" #mortalityFromDateDp="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="mortalityFromDateDp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="mortalityToDate">To</label>
              <div class="input-group">
                <input id="mortalityToDate" name="mortalityToDate" class="form-control" placeholder="dd-mm-yyyy"
                       [(ngModel)]="mortalityToDate" #mortalityToDateDp="ngbDatepicker"
                       ngbDatepicker/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fas fa-calendar-alt"
                          (click)="mortalityToDateDp.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group mx-sm-3 mb-2">
              <label for="house">House</label>
              <select class="form-control" id="house" name="site"
                      [(ngModel)]="houseId">
                <option value="">All</option>
                <option *ngFor="let house of houses" [value]="house.id">{{house.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-sm btn-primary mx-sm-3" (click)="search()" 
                      ngbAutofocus>
                Search
              </button>
              <button type="button" class="btn btn-primary btn-sm" (click)="add()">
                Add
              </button>
            </div>
          </div>
          <table class="table table-hover table-bordered" style="margin-top: 10px">
            <thead>
            <tr>
              <th>Flock Day</th>
              <th>House</th>
              <th>Date</th>
              <th>Completed By</th>
              <th>Gender</th>
              <th>Transfer In</th>
              <th>Decomposed</th>
              <th>Heat Stress</th>
              <th>Leg Problems</th>
              <th>Back Straches</th>
              <th>Cannibalism</th>
              <th>Starve Out</th>
              <th>Prolapse</th>
              <th>Peritonitis</th>
              <th>Injury</th>
              <th>Non Starter</th>
              <th>Yolk Sac Infection</th>
              <th>Navel Infection</th>
              <th>Vaccine Infection</th>
              <th>Sexing Error</th>
              <th>Cull Sale</th>
              <th>Depleted</th>
              <th>Transfer Out</th>
              <th>Opening</th>
              <th>Total</th>
              <th>Closing</th>
              <th>Standard</th>
              <th>Actual</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody *ngIf="mortalities">
            <tr *ngFor="let mortality of mortalities">
              <td>{{mortality.flockDay}}</td>
              <td>{{mortality.house}}</td>
              <td>{{mortality.date | date : 'dd MMM yyyy'}}</td>
              <td>{{mortality.completedBy}}</td>
              <td>{{mortality.gender}}</td>
              <td class="text-right">{{mortality.transferIn | number}}</td>
              <td class="text-right">{{mortality.decomposed | number}}</td>
              <td class="text-right">{{mortality.heatStress | number}}</td>
              <td class="text-right">{{mortality.legProblems | number}}</td>
              <td class="text-right">{{mortality.backScratches | number}}</td>
              <td class="text-right">{{mortality.cannibalism | number}}</td>
              <td class="text-right">{{mortality.starveOut | number}}</td>
              <td class="text-right">{{mortality.prolapse | number}}</td>
              <td class="text-right">{{mortality.peritonitis | number}}</td>
              <td class="text-right">{{mortality.injury | number}}</td>
              <td class="text-right">{{mortality.nonStarter | number}}</td>
              <td class="text-right">{{mortality.yolkSacInfection | number}}</td>
              <td class="text-right">{{mortality.navelInfection | number}}</td>
              <td class="text-right">{{mortality.vaccineInfection | number}}</td>
              <td class="text-right">{{mortality.sexingError | number}}</td>
              <td class="text-right">{{mortality.cullSale | number}}</td>
              <td class="text-right">{{mortality.depleted | number}}</td>
              <td class="text-right">{{mortality.transferOut | number}}</td>
              <td class="text-right">{{mortality.openingBalance | number}}</td>
              <td class="text-right">{{mortality.total | number}}</td>
              <td class="text-right">{{mortality.closingBalance | number}}</td>
              <td class="text-right">{{mortality.standard | number : '1.3'}} %</td>
              <td [class]="standardStatus(mortality.standard, mortality.actual)">{{mortality.actual | percent : '1.3'}}</td>
              <td>
                <button type="button" class="btn btn-link" title="Edit" *ngIf="authService.hasAuthority('Data Capture')"
                        (click)="edit(mortality)">
                  <span class="fas fa-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-link" title="Edit"
                        *ngIf="authService.hasAuthority('Data Capture')"
                        (click)="delete(mortality)">
                  <span class="fas fa-minus-circle" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>