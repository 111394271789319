import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {Business} from '../business';
import {Router} from '@angular/router';
import {ModalService} from '../../util/modal.service';
import {FarmFormComponent} from '../farm-form/farm-form.component';

@Component({
  selector: 'app-farm-list',
  templateUrl: './farm-list.component.html',
  styleUrls: ['./farm-list.component.css']
})
export class FarmListComponent implements OnInit {

  farms = [] as Business[];

  constructor(private backendService: BackendService,
              private router: Router,
              private modalService: ModalService) { }

  ngOnInit(): void {
    this.loadFarms();
  }

  private loadFarms() {
    this.backendService.get<Business[]>('business').then((res: Business[]) => {
      this.farms = res;
    });
  }

  async edit(farm: Business) {

    const modalRef = this.modalService.openRef(FarmFormComponent);

    const component = modalRef.componentInstance as FarmFormComponent;
    component.farm = farm;
    component.title = 'Edit ' + farm.name;
    await modalRef.result;
    this.loadFarms();
  }

  async add() {

    const modalRef = this.modalService.openRef(FarmFormComponent);

    const component = modalRef.componentInstance as FarmFormComponent;
    component.farm = { } as Business;
    component.title = 'Add a Farm '
    await modalRef.result;
    this.loadFarms();
  }

  details(farm: Business) {
    this.router.navigate(['farm', 'view', farm.id]);
  }
}
