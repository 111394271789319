<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Number</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="code" [(ngModel)]="employee.number" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Name</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="name" [(ngModel)]="employee.name" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Firstname</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="firstname" [(ngModel)]="employee.firstname" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Lastname</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input name="lastname" [(ngModel)]="employee.lastname" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Birthdate</label>
      </div>
      <div class="form-group col-sm-6">
        <div class="input-group">
          <input id="startDate" name="startDate" class="form-control" placeholder="yyyy-mm-dd"
                 [(ngModel)]="employeeBithdate" #birthdatePicker="ngbDatepicker"
                 ngbDatepicker/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary fas fa-calendar-alt"
                    (click)="birthdatePicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Email</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input type="email" name="flourArea" [(ngModel)]="employee.email"
                        stretch="true"></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Cell</label>
      </div>
      <div class="form-group col-sm-6">
        <app-text-input type="number" name="feederLength" [(ngModel)]="employee.cellnumber"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Authorities</label>
      </div>
      <div class="form-group col-sm-6">
        <div *ngFor="let authority of authorities">
          <label>
            <input type="checkbox"
                   [name]="authority"
                   [value]="authority"  [checked]="isA(authority)"
                   (change)="changeAuthority($event,authority)" required/> {{authority}}
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>