import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {ModalService} from '../../util/modal.service';
import {DatePipe, formatDate} from '@angular/common';
import {ChartDataSets, ChartOptions} from "chart.js";
import {Color, Label} from "ng2-charts";
import {FlockData} from "../../flock/flock-standard";

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html'
})
export class TemperatureChartComponent implements OnInit {
  houses!: House[];
  fromDate = new Date();
  toDate = new Date();
  houseId = '';
  flockData!: FlockData[];
  private farmId!: string;
  private standardMinData: Chart.ChartDataSets = {data: [], label: 'Standard Min', fill: false};
  private actualMinData: Chart.ChartDataSets = {data: [], label: 'Actual Min', fill: false};
  private standardMaxData: Chart.ChartDataSets = {data: [], label: 'Standard Max', fill: false};
  private actualMaxData: Chart.ChartDataSets = {data: [], label: 'Actual Max', fill: false};
  public lineChartData: ChartDataSets[] = [this.standardMinData, this.actualMinData, this.standardMaxData, this.actualMaxData];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation?: any }) = {
    responsive: true,
    title: {display: true, text: 'Temperature Chart'}
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  constructor(public authService: AuthService,
              private backendService: BackendService) {
  }

  ionViewWillEnter() {
    this.ngOnInit();
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.houses = await this.backendService.get('house/' + this.farmId);
    this.houseId = this.houses[0].id;
    await this.search();
  }

  async search() {
    const fromDate = this.fromDate.getTime();
    const toDate = this.toDate.getTime();
    this.flockData = await this.backendService.postSilently('flockData',
      {houseId: this.houseId, fromDate: fromDate, toDate: toDate});
    this.standardMinData.data = [];
    this.actualMinData.data = [];
    this.standardMaxData.data = [];
    this.actualMaxData.data = [];
    this.lineChartLabels = [];
    this.flockData.forEach((flockDatum: FlockData) => {
      this.standardMinData.data?.push(flockDatum.targetMinTemperature);
      this.actualMinData.data?.push(flockDatum.actualMinTemperature);
      this.standardMaxData.data?.push(flockDatum.targetMaxTemperature);
      this.actualMaxData.data?.push(flockDatum.actualMaxTemperature);
      this.lineChartLabels.push(flockDatum.ageDays);
    });
  }

}
