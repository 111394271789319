import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../../util/backend.service';
import {Site} from '../site';
import {FarmFormComponent} from '../../farm-form/farm-form.component';
import {SiteFormComponent} from '../site-form/site-form.component';
import {ModalService} from '../../../util/modal.service';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.css']
})
export class SiteListComponent implements OnInit {

  @Input() farmId!: string;
  sites!: Site[];
  constructor(private backendService: BackendService,
              private modalService: ModalService) { }

  async ngOnInit() {
    await this.load();
  }

  private async load() {
    this.sites = await this.backendService.get('site/' + this.farmId);
  }

  async edit(site: Site){
    const modalRef = this.modalService.openRef(SiteFormComponent);
    const component = modalRef.componentInstance as SiteFormComponent;
    component.site = site;
    component.title = 'Edit ' + site.name;
    await modalRef.result;
    this.load();
  }

  async add(){
    const modalRef = this.modalService.openRef(SiteFormComponent);
    const component = modalRef.componentInstance as SiteFormComponent;
    component.title = 'Add Site ';
    component.site = { farmId: this.farmId} as Site;
    await modalRef.result;
    this.load();
  }
}
