import {Component, OnInit} from '@angular/core';
import {House} from '../../house/house';
import {Employee} from '../employee';
import {NgbActiveModal, NgbCalendar, NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../../util/backend.service';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit {
  employee!: Employee;
  employeeBithdate: any;
  title!: string;
  defaultBirthdate = new Date();
  authorities = ['Administrator', 'Manager', 'Egg Collector', 'Foreman', 'Egg Packer', 'Data Capture']

  constructor(private backendService: BackendService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.defaultBirthdate.setFullYear(this.defaultBirthdate.getFullYear() - 20);
    if (this.employee.birthdate) {
      this.employeeBithdate = new Date(this.employee.birthdate);
    } else {
      this.employeeBithdate = this.defaultBirthdate;
    }
  }

  save() {
    this.employee.birthdate = this.employeeBithdate.getTime();
    if (this.employee.id) {
      this.backendService.putSilently('employee', this.employee).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {

      });
    } else {
      this.backendService.postSilently('employee', this.employee).then((res: any) => {
        this.activeModal.close(res);
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  changeAuthority(e: any, authority: string) {
    if (!this.employee.authorities) {
      this.employee.authorities = [];
    }

    if (e.target.checked) {
      this.employee.authorities.push(authority);
    }
    else {
      this.employee.authorities = this.employee.authorities.filter(l => l !== authority);
    }
  }

  isA(authority: string) {
    const employeeAuthority = this.employee.authorities && this.employee.authorities.find(l => l === authority);
    return employeeAuthority;
  }
}
