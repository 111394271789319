import {Component, OnInit} from "@angular/core";
import {BackendService} from "../../util/backend.service";
import {ActivatedRoute} from "@angular/router";
import {StandardImport} from "../../standard/standard-import";

@Component({
  selector: 'app-temperature-standard-view',
  templateUrl: './temperature-standard-view.component.html'
})
export class TemperatureStandardViewComponent implements OnInit {

  standardImport!: StandardImport;

  constructor(private backendService: BackendService,
              private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const standardHeaderId = this.route.snapshot.params.id;
    this.standardImport = await this.backendService.get('standard/' + standardHeaderId);
  }

}