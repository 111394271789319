import {Component, OnInit} from '@angular/core';
import {BackendService} from '../../util/backend.service';
import {AuthService} from '../../auth/auth.service';
import {House} from '../../farm/house/house';
import {ModalService} from '../../util/modal.service';
import {MortalityFormComponent} from '../mortality-form/mortality-form.component';
import {DatePipe, formatDate} from '@angular/common';
import {Mortality} from "../mortality";

@Component({
  selector: 'app-mortality-list',
  templateUrl: './mortality-list.component.html'
})
export class MortalityListComponent implements OnInit {
  houses!: House[];
  mortalityFromDate = new Date();
  mortalityToDate = new Date();
  houseId = '';
  mortalities!: Mortality[];
  private farmId!: string;

  constructor(public authService: AuthService,
              private backendService: BackendService,
              private datePipe: DatePipe,
              private modalService: ModalService) {
  }

  ionViewWillEnter() {
    this.ngOnInit();
  }

  async ngOnInit() {
    this.farmId = this.authService.getFarmId();
    this.houses = await this.backendService.get('house/' + this.farmId);
    await this.search();
  }

  async search() {
    const fromDate = this.mortalityFromDate.getTime();
    const toDate = this.mortalityToDate.getTime();
    this.mortalities = await this.backendService.postSilently('mortality',
      {houseId: this.houseId, fromDate: fromDate, toDate: toDate});
  }

  status(status: string) {
    switch (status) {
      case 'Pending':
        return 'text-warning';
      case 'Confirmed':
        return 'text-success';
      case 'Exception':
        return 'text-danger';
    }
    return 'text-info';
  }

  async add() {
    const modalRef = this.modalService.openRef(MortalityFormComponent, 'lg');
    const component = modalRef.componentInstance as MortalityFormComponent;
    component.title = 'Add Mortality ';
    component.mortality = {} as Mortality;
    await modalRef.result;
    this.search();
  }

  async edit(mortality: Mortality) {
    const modalRef = this.modalService.openRef(MortalityFormComponent, 'lg');
    const component = modalRef.componentInstance as MortalityFormComponent;
    component.title = 'Edit Collection ';
    component.mortality = mortality;
    await modalRef.result;
    this.search();
  }

  async delete(collection: Mortality) {
    this.modalService.confirm('Deleting mortality (' + collection.house + formatDate(this.mortalityFromDate, ' - dd MMMM yyyy', 'en')
      + ') will erase it from the system. Are you sure you want to delete this mortality?' )
      .then( response => {
        if (response){
          this.backendService.delete('mortality/' + collection.id).then( response => {
            this.modalService.info('Mortality successfully deleted');
            this.search();
          });
        }
        }, errObj => {
        this.modalService.error('Error occured while deleting the collection.');
        }
      );
  }

  standardStatus(standard: number, actual: number) {
    if(standard < actual) {
      return 'text-right text-danger font-weight-bold';
    } else {
      return 'text-right text-success font-weight-bold';
    }
  }
}
