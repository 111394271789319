<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel" translate>{{title}}</h4>
</div>

<div class="modal-body">

  <form #siteForm="ngForm" (ngSubmit)="save()">
    <div class="form-row">
      <div class="form-group col-10">
        <label>Name</label>
        <app-text-input name="name" [(ngModel)]="feedType.name" stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-10">
        <label>Crude Protein Percentage (0 - 1)</label>
        <app-text-input type="number" [appMin]="0.001" appMax="1" 
                        name="crudeProteinPercentage"
                        [(ngModel)]="feedType.crudeProteinPercentage"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-10">
        <label>Lysine Percentage (0 - 1)</label>
        <app-text-input type="number" [appMin]="0.001" appMax="1" 
                        name="lysinePercentage"
                        [(ngModel)]="feedType.lysinePercentage"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-10">
        <label>Energy Per Kilogram (KCal)</label>
        <app-text-input type="number"
                        name="energyPerKilogram"
                        [(ngModel)]="feedType.energyPerKilogram"
                        stretch="true" required></app-text-input>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary right"
              [disabled]="!siteForm.valid" ngbAutofocus>
        Save
      </button>

      <button type="button" class="btn btn-secondary left mr-1"
              (click)="activeModal.close()" ngbAutofocus>
        Cancel
      </button>
    </div>
  </form>
</div>