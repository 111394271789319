import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  Optional,
  QueryList,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, NgSelectOption } from '@angular/forms';
import { SimpleValueAccessor } from './simple-value-accessor';

@Component({
  selector: 'app-drop-down',
  template: `
    <!--suppress TypeScriptUnresolvedVariable -->
    <select
      class="form-control"
      [ngStyle]="{ display: 'inline', width: computedWidth }"
      #select
      [attr.name]="name"
      [ngModel]="value"
      (ngModelChange)="onNgModelChange($event)"
      (blur)="onTouched()"
      [disabled]="disabled"
    >
      <ng-content></ng-content>
    </select>
    <app-control-errors
      [ngClass]="computedWidth === '100%' ? [] : ['ml-2']"
      [control]="controlDirective.control"
    >
    </app-control-errors>
  `,
  styles: [':host {display: flex; flex-wrap: wrap}'],
})
export class DropDownComponent
  extends SimpleValueAccessor
  implements AfterViewInit {
  @Input() name?: string;
  @Input() stretch = false;
  @Input() width = '200px';
  @ViewChild('select') selectElement!: ElementRef;

  @ContentChildren(NgSelectOption)
  selectOptions!: QueryList<NgSelectOption>;

  value: any;

  constructor(@Optional() @Self() controlDirective: NgControl) {
    super(controlDirective);
  }

  get computedWidth() {
    return this.stretch ? '100%' : this.width;
  }

  ngAfterViewInit() {
    this.selectOptions.changes.subscribe(() => {
      // Ensure the correct option is selected once all have loaded
      this.selectElement.nativeElement.value = this.value;
    });
  }

  onNgModelChange(value: any) {
    // Even with <option [value]="null"> we still get 'null' as a string
    this.value = value === 'null' ? null : value;
    this.onChange(this.value);
  }

  writeValue(value: any) {
    this.value = value;
  }
}
